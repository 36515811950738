import Plugin from 'lib/modulor/plugin'

export default class AspectRatioThumbnail extends Plugin
  @defaults =
    debug: false
    # name: 'ExhibitionPage__Thumbnail__Image'

  # @selector: ".exhibition_page__thumbnail__image"

  on_init: ->
    @aspect_ratio = @get_aspect_ratio()
    @set_padding_bottom()

  on_resize: ->
    new_aspect_ratio = @get_aspect_ratio()
    return unless Math.abs(Math.round( new_aspect_ratio * 10 ) - Math.round( @aspect_ratio * 10 )) > 1
    @aspect_ratio = new_aspect_ratio
    @set_padding_bottom()

  get_modulor_attachment: -> @$element.find('.modulor_attachment__responsive')
  get_aspect_ratio: ->
    res = window.innerHeight / window.innerWidth
    res = res / 2 if res > 1
    res

  set_padding_bottom: ->
    @get_modulor_attachment().css('padding-bottom', @aspect_ratio * 100 + "%";)
