import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'SimpleFormAttachments__Wrapper'

  @selector: ".simple_form_attachments"

  on_init: ->
    { default: SimpleFormAttachments } = await `import('./simple_form_attachments' /* webpackChunkName: "simple_form_attachments" */)`
    @plugin_instance = new SimpleFormAttachments(@element)

  on_destroy: ->
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()
