import Character from './character'
import Img from './img'

export default class Canvas
  @defaults =
    debug: false
    color: 'rgb(0,0,0)'
    bgColor: 'rgb(255,255,255)'
    dpi: 1.25
    lineWidth: 10

  constructor: (canvas, options = {}) ->
    @canvas = canvas
    @options = Object.assign {}, @constructor.defaults, options

    @timeout
    @init()

  init: ->
    @setCanvasSize()
    @setupCharacters()
    @setupImages()
    @render()

    @getContext()

    @boundHandleScroll = @handleScroll.bind(@)
    @boundHandleResize = @handleResize.bind(@)

    window.addEventListener 'scroll', @boundHandleScroll
    window.addEventListener 'resize', @boundHandleResize

  destroy: ->
    window.removeEventListener 'scroll', @boundHandleScroll if @boundHandleScroll
    window.removeEventListener 'resize', @boundHandleResize if @boundHandleResize

    @boundHandleScroll = null
    @boundHandleResize = null

    character.destroy() for character in @characters if @characters
    image.destroy() for image in @images if @images

  handleResize: ->
    @setCanvasSize()
    @render()

  handleScroll: ->
    window.cancelAnimationFrame(@timeout) if @timeout
    @timeout = window.requestAnimationFrame @render.bind(this)

  getCharacters: -> @options.characters
  getImgTags: -> @options.images

  getWindowWidth: -> window.innerWidth
  getWindowHeight: -> window.innerHeight

  getContext: -> @context or= @canvas.getContext('2d')

  setLineWidth: (lineWidth) ->
    return unless @characters

    @options.lineWidth = lineWidth

    for character, index in @characters
      character.setDimensions(@canvas.width, @canvas.height, lineWidth)


  isResize: ->
    @getWindowWidth() * @options.dpi != @canvas.width ||
      @getWindowHeight() * @options.dpi != @canvas.height

  setupCharacters: ->
    @characters = []
    for character in @getCharacters()
      @characters.push new Character(character, dpi: @options.dpi, lineWidth: @options.lineWidth, color: @options.color)

  setupImages: ->
    @images = []
    for img, i in @getImgTags()
      @images.push new Img(img, @render.bind(this), dpi: @options.dpi)

  setCanvasSize: ->
    @canvas.width = @getWindowWidth() * @options.dpi
    @canvas.height = @getWindowHeight() * @options.dpi

    @canvas.style.width = @getWindowWidth() + "px"
    @canvas.style.height = @getWindowHeight() + "px"

  renderBackground: ->
    ctx = @getContext()
    ctx.fillStyle = @options.bgColor
    ctx.fillRect(0, 0, @canvas.width, @canvas.height)

  renderCharacters: ->
    ctx = @getContext()

    for character, index in @characters
      character.setDimensions(@canvas.width, @canvas.height, @options.lineWidth)

    firstCharacterWidth = @characters[0].getWidth()
    lastCharacterWidth = @characters[..].pop().getWidth()
    availableWidth = @canvas.width - firstCharacterWidth / @characters.length * 1.0 - lastCharacterWidth / @characters.length * 1.0

    for character, index in @characters
      x = switch index
        when 0 then 0
        when 1
          if @characters.length == 3
            @canvas.width / 2.0 - character.getWidth() / 2.0
          else
            availableWidth / (@characters.length) * index * 1.0
        when (@characters.length - 1) then Math.round(@canvas.width - character.getWidth())
        else availableWidth / (@characters.length) * index * 1.0
      y = 0

      character.drawImage(ctx, Math.round(x), Math.round(y))

  renderImages: ->
    ctx = @getContext()
    x = 0
    y = - Math.round(window.scrollY) * @options.dpi

    for image, index in @images
      image.setDimensions(@canvas.width, @canvas.height)
      image.drawImage(ctx, x, y, index)
      y += @canvas.height

  render: ->
    if @images.length > 0
      @getContext().globalCompositeOperation = 'source-over'
      @renderBackground()
      @renderImages()
      @getContext().globalCompositeOperation = 'destination-out'

    @renderCharacters()
