import Plugin from 'lib/modulor/plugin'

export default class Filesize extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Filesize'

  @selector: '[data-filesize]'

  on_init: ->
    { default: @numeral } = await `import('numeral' /* webpackChunkName: "numeral" */)`
    @$element.html(@get_converted_value())

  get_filesize: -> @$element.data 'filesize'
  get_converted_value: -> @numeral(@get_filesize()).format('0.0 b')

Filesize.register()
