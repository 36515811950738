import Plugin from 'lib/modulor/plugin'

import Canvas from 'krefeld-overlay/src/canvas'

export class _Canvas extends Plugin
  @defaults =
    debug: false
    name: 'Overlay__Canvas'
    dpi: 1

  @selector: ".overlay__canvas"

  on_init: ->
    @canvas or= new Canvas(
      @element,
      color: @get_color(),
      bgColor: @get_bg_color(),
      dpi: @options.dpi,
      characters: @get_characters(),
      images: @get_images(),
      lineWidth: @get_line_width()
    )

    $('body > .modulor_page').css('overflow', 'hidden').css('max-height', "#{window.innerHeight + @get_images().length}px")

  on_destroy: ->
    @canvas.destroy() if @canvas
    $('body > .modulor_page').css('overflow', '').css('max-height', '')

  on_resize: ->
    @canvas.setLineWidth(@get_line_width()) if @canvas
    $('body > .modulor_page').css('overflow', 'hidden').css('max-height', "#{window.innerHeight + @get_images().length}px")


  get_images: ->
    return [] if @is_friends()
    current_path = @get_current_path()

    @get_current_page()
      .find('.modulor_attachment[data-file-type="image"]')
      .find('img[data-aspect-ratio]')
      .not('.overlay__ignore')
      .filter (i, el) ->
        return true unless current_path == 'Visit'
        return false if $(el).closest('.edition_list_module__content[data-view-option="selected"]').length > 0
        return false if $(el).closest('.publication_list_module__content[data-view-option="selected"]').length > 0
        true

  get_current_page: -> $('.modulor_page__is_current')
  get_current_path: -> $('body').data('path')

  get_color: ->
    if @is_friends()
      'rgb(47,113,118)'
    else
      'rgb(0,0,0)'

  get_bg_color: ->
    if @is_past()
      'rgb(199,199,199)'
    else
      'rgb(255,255,255)'

  get_characters: ->
    if @is_friends()
      'FREUNDE'.split(/\s*/)
    else
      ($('body').data('overlay') || 'KWMHLHE').split(/\s*/)

  get_line_width: ->
    if window.matchMedia( "(max-width: 739px)" ).matches # xs
      8
    else if window.matchMedia( "(min-width: 740px) and (max-width: 999px)" ).matches # s
      10
    else if window.matchMedia( "(min-width: 1000px) and (max-width: 1279px)" ).matches # m
      10
    else if window.matchMedia( "(min-width: 1280px)" ).matches # l
      12
    else
      12

  is_past: -> !!$('body').data('past')
  is_friends: -> @get_current_path() == 'Friends'

# OLD
# _Canvas.register()
