if (!global._babelPolyfill) {
  require("@babel/polyfill")
}

import '../initializers/modulor'

import '../components/modulor'

import '../plugins/modulor'

import '../models/modulor'
import '../models/pages'
import '../models/web_modules'

import '../form_objects/modulor'
import '../view_objects/modulor'

import '../shared/modulor'
