import MutationObserver from 'lib/modulor/mutation_observer'

MutationObserver.register(
  'picture',
  ((el) ->
    picturefill = await `import('picturefill' /* webpackChunkName: "picturefill" */)`
    picturefill(elements: [el])
  ),
  # ((el) -> ) # TODO: destroy
)
