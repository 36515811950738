export default class E
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    ctx.moveTo(
      Math.round(588 * scaleRatio + offsetX),
      Math.round(0 * scaleRatio + offsetY) + ctx.lineWidth / 2.0
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(0 * scaleRatio + offsetY) + ctx.lineWidth / 2.0
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY) - ctx.lineWidth / 2.0
    )
    ctx.lineTo(
      Math.round(588 * scaleRatio + offsetX),
      Math.round(1000 * scaleRatio + offsetY) - ctx.lineWidth / 2.0
    )

    ctx.stroke()

    ctx.beginPath()

    ctx.moveTo(
      Math.round(588 * scaleRatio + offsetX),
      Math.round(493 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(493 * scaleRatio + offsetY)
    )

    ctx.stroke()

  getWidth: (scaleRatio) ->
    588 * scaleRatio
