export default class N
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    ctx.moveTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1010 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(-10 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(650 * scaleRatio + offsetX),
      Math.round(1010 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(650 * scaleRatio + offsetX - ctx.lineWidth / 2.0),
      Math.round(-10 * scaleRatio + offsetY)
    )

    ctx.stroke()

  getWidth: (scaleRatio) ->
    650 * scaleRatio
