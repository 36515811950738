# import { default as WebModule__Content } from 'models/modulor/web_module/content'

# export class Content extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'ShopModule__Content'

#   @selector: ".shop_module__content"

#   on_init: ->
#     return unless @get_related_pages().length > 1

#     { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

#     @flickity = new Flickity(@get_container()[0],
#       adaptiveHeight: true
#       cellAlign: 'left'
#       cellSelector: "li[class*='thumbnail']"
#       initialIndex: 0
#       pageDots: true
#       prevNextButtons: false
#     )

#   on_destroy: ->
#     @flickity.destroy() if @flickity
#     @$element.off '.flickity'

#   get_container: -> @$element.find(".shop_module__thumbnails")
#   get_related_pages: -> @$element.find("li[class*='thumbnail']")

# Content.register()
