import './styles'

import PropTypes from 'prop-types'
import { Component } from 'react'

export default class Loading extends Component
  @propTypes =
    className: PropTypes.string.isRequired
    value: PropTypes.string.isRequired

  @defaultProps =
    className: "modulor_loading"
    value: 'Loading …'

  render: ->
    <div className={this.props.className}>
      {this.props.value}
    </div>
