import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Modal extends Plugin
  @selector: "html"

  @property "current_path",
    get: -> window.location.pathname

  @property "user_signed_in",
    get: -> document.body.classList.contains("user_signed_in")

  on_init: ->
    @pop_state_handler = (e) => @on_pop_state(e.originalEvent)
    $(window).on 'popstate', @pop_state_handler

    @$element.on "click.#{@options.name}", (e) =>
      if $(e.target).is(".article_page__is_modal")
        history.back()


    @$element.on "click.#{@options.name}", "a[data-modal='true']", (e) =>
      e.preventDefault()
      url = e.target.getAttribute("href")
      if @user_signed_in
        Turbolinks.visit(url)
      else
        history.pushState({ url: url, modal: true, previousUrl: @current_path }, "", url)
        @load_modal(url, @current_path)

    @$element.on "click.#{@options.name}", ".article_page__link_to__close", (e) =>
      e.preventDefault()
      url = e.target.getAttribute("href")
      if @user_signed_in
        Turbolinks.visit(url)
      else
        history.pushState({ url: url, modal: false, previousUrl: @current_path }, "", url)
        @close_modal(url)

  on_keyup: (e) ->
    if e.keyCode == 27
      history.back()

  on_destroy: ->
    $(window).off('popstate', @pop_state_handler) if @pop_state_handler

  on_pop_state: (e) =>
    return unless e.state.url && e.state.previousUrl
    if e.state.modal
      @load_modal(e.state.url, e.state.previousUrl)
    else
      @close_modal(e.state.url, e.state.previousUrl)

  load_modal: (url, previousUrl = @current_path) ->
    $.ajax
      type: "GET"
      url: url
      headers: { "modulor-skip-layout": true, 'modal-only': true }
      success: (data, status, xhr) =>
        $("body").find(".article_page.modulor_page__is_current").remove()
        $loadedPage = $(data).appendTo($("body"))
        $loadedArticlePage = $loadedPage.find(".article_page__is_modal")
        $loadedArticlePage.children(".modulor_web_modules").css("left", "100vw").css("transition", "left .75s")

        setTimeout (=>
          $loadedArticlePage.children(".modulor_web_modules").css("left", "0")
          @update_body_attrs($loadedArticlePage[0])
          @update_html_title($loadedArticlePage[0])
        ), 250

  close_modal: (url) ->
    article_page = $.find(".article_page.modulor_page__is_current")[0]
    currentPage = document.querySelector(".modulor_page[data-path='#{url.substr(4)}']")
    currentPage.classList.add("modulor_page__is_current")
    @update_body_attrs(currentPage)
    @update_html_title(currentPage)
    article_page.remove() if article_page

  update_body_attrs: (page) ->
    return unless page
    for name in ["data-path", "data-page-type"]
      document.body.setAttribute(name, page.getAttribute(name))

  update_html_title: (page) ->
    document.head.querySelector("title").innerText = page.getAttribute("data-html-title")

Modal.register()
