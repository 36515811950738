import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./attachment_list' /* webpackChunkName: "attachment_list" */)`,
  loading: -> <Loading />

export default class LoadableAttachmentList extends Component
  render: -> <LoadableComponent {...this.props} />
