export default class D
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    p = new Path2D(
      "
      M #{270 * scaleRatio + offsetX}, #{1002 * scaleRatio + offsetY}
      c #{330 * scaleRatio}, #{0 * scaleRatio}, #{444 * scaleRatio} #{-211 * scaleRatio}, #{444 * scaleRatio} #{-510 * scaleRatio}
      C #{714 * scaleRatio + offsetX}, #{152 * scaleRatio + offsetY}, #{554 * scaleRatio + offsetX}, #{2 * scaleRatio + offsetY}, #{226 * scaleRatio + offsetX}, #{2 * scaleRatio + offsetY + ctx.lineWidth / 4.0}
      H #{0 * scaleRatio + offsetX}
      v #{46 * scaleRatio}
      v #{953 * scaleRatio}
      H #{270 * scaleRatio + offsetX}
      z
      "
    )

    # M (x,y)
    # m (dx,dy)
    # L (x, y)
    # l (dx, dy)
    # c (dx1,dy1, dx2,dy2, dx,dy)
    # C (x1,y1, x2,y2, x,y)
    # v dy
    # H x

    ctx.stroke(p)

  getWidth: (scaleRatio) ->
    714 * scaleRatio
