import Plugin from 'lib/modulor/plugin'

export default class DateRangePickers extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__DateRangePickers'

  @selector: ".modulor_date_range_pickers"

  on_init: ->
    @listen_to_change = true

    { default: @moment } = await `import('moment' /* webpackChunkName: "moment" */)`

    @$element.on "change.#{@options.name}", ".modulor_date_picker__input", (e) =>
      return unless $(e.target)[0] == @get_date_from_input()[0]
      return unless date_to_input = @get_date_to_input()[0]
      return unless date_to_instance = date_to_input._flatpickr
      date_to_instance.set('minDate', @get_date_time_from())

    @$element.on "change.#{@options.name}", ".modulor_date_picker__input, .modulor_time_picker__input", (e) =>
      if @listen_to_change
        @listen_to_change = false
        @on_change()
        @listen_to_change = true

  on_destroy: ->
    @listen_to_change = null

  get_date_picker_inputs: -> @$element.find(".modulor_date_picker__input:not([type='hidden'])")
  get_time_picker_inputs: -> @$element.find(".modulor_time_picker__input:not([type='hidden'])")

  get_date_from_input: -> @get_date_picker_inputs().filter(':first')
  get_time_from_input: -> @get_time_picker_inputs().filter(':first')

  get_date_to_input: -> @get_date_picker_inputs().filter(':last')
  get_time_to_input: -> @get_time_picker_inputs().filter(':last')

  get_date_time_from: -> @get_date_time(@get_date_from_input()[0], @get_time_from_input()[0])
  get_date_time_to: -> @get_date_time(@get_date_to_input()[0], @get_time_to_input()[0])

  get_date_time: (date_input, time_input) ->
    return unless date_input
    return unless date_instance = date_input._flatpickr
    return unless date = date_instance.selectedDates[0]

    date = @moment(date).hour(12).toDate()

    return date unless time_input
    return date unless time_instance = time_input._flatpickr
    return date unless time = time_instance.selectedDates[0]

    res = @moment(date)
    res = res.hour(@moment(time).hour())
    res = res.second(@moment(time).second())
    res.toDate()

  on_change: ->
    return unless date_time_from = @get_date_time_from()

    unless @get_time_from_input().val()
      time_from_input = @get_time_from_input()[0]
      if time_from_instance = time_from_input._flatpickr
        time_from_instance.setDate(date_time_from, true)

    unless @get_time_to_input().val()
      date_to_input = @get_date_to_input()[0]
      if date_to_instance = date_to_input._flatpickr
        date_to_instance.setDate(date_time_from, true)

      time_to_input = @get_time_to_input()[0]
      if time_to_instance = time_to_input._flatpickr
        time_to_instance.setDate(date_time_from, true)

    return unless date_time_to = @get_date_time_to()
    return unless date_to_input = @get_date_to_input()[0]
    return unless date_to_instance = date_to_input._flatpickr

    return if date_time_from <= date_time_to

    if time_to_input = @get_time_to_input()[0]
      if time_to_instance = time_to_input._flatpickr
        time_to_instance.setDate(date_time_from, true)

    date_to_instance.setDate(date_time_from, true)

DateRangePickers.register()
