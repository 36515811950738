import _debounce from 'lodash/debounce'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class WithThumbnails extends WebModule__Content
  @defaults =
    debug: false
    debounce: 100
    name: 'ImagesModule__WithThumbnails'

  @selector: ".images_module__content[data-view-option='with_thumbnails']"

  @property "click_to_full_size",
    get: ->
      return false if @$element.data("click-to-full-size") == "false"
      return false if @$element.data("click-to-full-size") == false
      true

  on_init: ->
    @$lightbox_markup or= @get_attachments_container().clone()

    { default: @Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new @Flickity(@get_attachments_container()[0],
      adaptiveHeight: false
      cellAlign: 'left'
      cellSelector: ".images_module__attachment"
      groupCells: "100%"
      initialIndex: 0
      pageDots: false
      prevNextButtons: false
    )

    if @click_to_full_size
      @$element.on "staticClick.flickity", '.images_module__attachments .images_module__attachment', (e) => @open_lightbox($(e.currentTarget).index())
      @get_attachments_container().find(".images_module__attachment").css("cursor", "pointer");

    @$element.on "select.flickity", (e, index) => @set_nav_index(index)

    @$element.on "click.#{@options.name}", ".images_module__buttons__button__prev", (e) => @flickity.previous()
    @$element.on "click.#{@options.name}", ".images_module__buttons__button__next", (e) => @flickity.next()
    @$element.on "click.#{@options.name}", ".images_module__buttons__button__close", (e) => @close_lightbox()

    @font_load_handler = _debounce(@resize_flickity.bind(this), @options.debounce)
    $('html').on "font-load.#{@options.name}", @font_load_handler

    @$element.prepend @get_nav_buttons_markup() if @get_attachments().length > 1

    @set_nav_index(@flickity.selectedIndex)

  on_keyup: (e) ->
    return unless @get_lightbox().length

    if e.keyCode == 27
      @close_lightbox()

  on_destroy: ->
    @get_attachments_container().find(".images_module__attachment").css("cursor", "auto");
    @get_nav_buttons().remove()

    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    $('html').off('font-load', @font_load_handler) if @font_load_handler

  get_attachments_container: -> @$element.find("> .images_module__attachments")
  get_attachments: -> @get_attachments_container().find('.images_module__attachment')

  get_lightbox: -> @$element.find(".images_module__attachments__lightbox")

  get_prev_button_markup: -> $("
    <button class='images_module__buttons__button images_module__buttons__button__prev'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 0,50 50,100' />
      </svg>
    </button>
  ")

  get_next_button_markup: -> $("
    <button class='images_module__buttons__button images_module__buttons__button__next'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 100,50 50,100' />
      </svg>
    </button>
  ")

  get_nav_buttons: -> @$element.find('> .images_module__buttons__nav')
  get_prev_button: -> @get_nav_buttons().find('.images_module__buttons__button__prev')
  get_next_button: -> @get_nav_buttons().find('.images_module__buttons__button__next')

  get_nav_buttons_markup: -> $("<div class='images_module__buttons__nav' />").append(@get_prev_button_markup()).append(@get_next_button_markup())

  resize_flickity: -> @flickity.resize() if @flickity

  set_nav_index: (index) ->
    if @flickity.slides.length <= 1
      @get_prev_button().addClass('images_module__buttons__button__inactive')
      @get_next_button().addClass('images_module__buttons__button__inactive')
    else
      @get_prev_button().toggleClass('images_module__buttons__button__inactive', (index == 0))
      @get_next_button().toggleClass('images_module__buttons__button__inactive', (index >= @flickity.slides.length - 1))

  open_lightbox: (index) ->
    if @get_lightbox().length
      @get_lightbox().ImagesModule__Attachments__Lightbox('select', index)

    else
      $lightbox = @$lightbox_markup.clone()
      $lightbox.removeClass("images_module__attachments")
      $lightbox.addClass("images_module__attachments__lightbox")
      $lightbox.data('initial-index', index)
      @$element.append $lightbox

    ScrollIntoView.call(@get_lightbox(), partially: true)

  close_lightbox: ->
    $lightbox = @get_lightbox()
    $lightbox.remove() if $lightbox
    ScrollIntoView.call(@get_attachments_container(), partially: true)

WithThumbnails.register()
