# TODO: lazyload images (no need to load images long below viewport)

export default class Img
  @defaults =
    debug: false

  constructor: (img, onLoadCallback, options = {}) ->
    @img = img
    @onLoadCallback = onLoadCallback
    @options = Object.assign {}, @constructor.defaults, options

    @canvasSrc
    @canvasWidth = 1
    @canvasHeight = 1

    @imageWidth
    @imageHeight

    @canvas = document.createElement('canvas')
    @image = new Image(@imageWidth, @imageHeight)

  destroy: ->
    @canvas.remove()
    @image.remove()

  handleOnLoad: ->
    @drawImageToOffscreenCanvas()
    @onLoadCallback()

  getContext: -> @context or= @canvas.getContext('2d')
  getAspectRatio: -> @img.getAttribute('data-aspect-ratio')
  getCanvasAspectRatio: -> @canvasWidth / @canvasHeight
  getHeight: -> @height
  getImg: -> @img
  getSrc: -> @img.getAttribute('src')
  getSrcSet: -> @img.getAttribute('data-srcset').split(/\s*,\s*/).map (src) ->
    res = src.split(/\s+/)
    { src: res[0], w: parseInt(res[1]) }

  getSmallestWidth: ->
    widths = @getSrcSet().map ({ src, w }) -> w
    widths.reduce (a, b) -> Math.min(a, b)

  getBestWidth: ->
    widths = @getSrcSet().map ({ src, w }) -> w
    dstWidth = if @getAspectRatio() > @getCanvasAspectRatio() then @canvasHeight * @getAspectRatio() else @canvasWidth
    widths.reduce (prev, curr) =>
      if Math.abs(curr - dstWidth) < Math.abs(prev - dstWidth) then curr else prev

  getBestSrc: ->
    bestWidth = @getBestWidth()
    # bestWidth = @getSmallestWidth()
    @getSrcSet().find(({ src, w }) -> w == bestWidth).src

  isSrcChange: -> @getSrc() != @getBestSrc()

  setDimensions: (canvasWidth, canvasHeight) ->
    @canvasWidth = canvasWidth if @canvasWidth != canvasWidth
    @canvasHeight = canvasHeight if @canvasHeight != canvasHeight

  drawImageToOffscreenCanvas: ->
    return unless (@image.src != @canvasSrc) || (@canvas.width != @canvasWidth) || (@canvas.height != @canvasHeight)

    @canvasHeight += 300 # to compensate for ios

    ctx = @getContext()

    @canvas.width = @canvasWidth
    @canvas.height = @canvasHeight

    imgAspectRatio = @getAspectRatio()
    canvasAspectRatio = @getCanvasAspectRatio()

    if imgAspectRatio > canvasAspectRatio
      # match height
      cropWidth = @image.height * canvasAspectRatio
      ctx.drawImage @image, Math.round((@image.width - cropWidth)/2), 0, cropWidth, @image.height, 0, 0, Math.round(@canvas.height * canvasAspectRatio), @canvas.height
    else
      # match width
      cropHeight = @image.width / canvasAspectRatio
      ctx.drawImage @image, 0, Math.round((@image.height - cropHeight)/2), @image.width, cropHeight, 0, 0, @canvas.width, Math.round(@canvas.width / canvasAspectRatio)

  drawImage: (ctx, dx, dy, index) ->
    return if dy - window.scrollY > @canvasHeight * @options.dpi
    return if dy + @canvasHeight < 0

    bestSrc = @getBestSrc()
    if @canvasSrc != bestSrc
      @image.src = bestSrc
      @canvasSrc = bestSrc
      @image.onload = null
      @image.onload = @handleOnLoad.bind(@)

    ctx.drawImage @canvas, dx, dy
