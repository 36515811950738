import 'flatpickr/dist/themes/light'

import Plugin from 'lib/modulor/plugin'

export class Calendar extends Plugin
  @defaults =
    debug: false
    name: 'EventListModule__Nav__Item__Calendar'
    button_selector: '.event_list_module__button_to__calendar'

  @selector: ".event_list_module__nav__item__calendar"

  on_init: ->
    @close_calendar()

    @click_handler = (e) =>
      return unless @element
      return if $(e.target).closest('.event_list_module__nav__item__calendar').length
      @close_calendar()
    $(document).on "click.#{@options.name}", @click_handler.bind(this)

    { default: @flatpickr } = await `import('flatpickr' /* webpackChunkName: "flatpickr" */)`
    @$element.on "click.#{@options.name}", @options.button_selector, (e) => @toggle_calendar()

  on_destroy: ->
    @close_calendar()

  get_button: -> @$element.find @options.button_selector
  get_date_from: -> @get_button().data 'date-from'
  get_path_template: -> @get_button().data 'path-template'

  toggle_calendar: ->
    if @instance then @close_calendar() else @open_calendar()

  open_calendar: ->
    @instance = @flatpickr @element,
      appendTo: @element
      defaultDate: new Date(@get_date_from())
      inline: true
      onChange: (dates, str, instance) => @navigate_to_date(dates[0])

  close_calendar: ->
    return unless @instance
    @instance.destroy()
    @instance = undefined

  navigate_to_date: (date) ->
    date_string = "#{date.getFullYear()}-#{date.getMonth() + 1}-#{date.getDate()}"
    url = @get_path_template().replace("--DATE--", date_string)
    Turbolinks.visit(url)

Calendar.register()
