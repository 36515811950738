# export class EventList extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'EventListModule'
#
#   @selector: ".event_list_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# EventList.register()
