import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Carousel extends WebModule__Content
  @defaults =
    debug: false
    debounce: 100
    name: 'AnnouncementModule__Carousel'

  @selector: ".announcement_module__content[data-display-option='carousel']"

  on_init: ->
    return unless @get_items().length > 1

    { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new Flickity(@get_container()[0],
      adaptiveHeight: false
      cellAlign: 'left'
      cellSelector: ".announcement_module__item"
      initialIndex: 0
      pageDots: false
      prevNextButtons: false
    )

    @$element.on "select.flickity", (e, index) =>
      return unless $(e.target).is(".announcement_module__items")
      @set_nav_index(index)

    @$element.on "click.#{@options.name}", ".announcement_module__buttons__button__prev", (e) => @flickity.previous()
    @$element.on "click.#{@options.name}", ".announcement_module__buttons__button__next", (e) => @flickity.next()

    @$element.prepend @get_nav_buttons_markup() if @get_items().length > 1

    @font_load_handler = _debounce(@resize_flickity.bind(this), @options.debounce)
    $('html').on "font-load.#{@options.name}", @font_load_handler

    @set_nav_index(@flickity.selectedIndex)

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    $('html').off('font-load', @font_load_handler) if @font_load_handler

  get_container: -> @$element.find(".announcement_module__items")
  get_items: -> @$element.find(".announcement_module__item")

  get_prev_button_markup: -> $("
    <button class='announcement_module__buttons__button announcement_module__buttons__button__prev'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 0,50 50,100' />
      </svg>
    </button>
  ")

  get_next_button_markup: -> $("
    <button class='announcement_module__buttons__button announcement_module__buttons__button__next'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 100,50 50,100' />
      </svg>
    </button>
  ")

  get_nav_buttons: -> @$element.find('> .announcement_module__buttons__nav')
  get_prev_button: -> @get_nav_buttons().find('.announcement_module__buttons__button__prev')
  get_next_button: -> @get_nav_buttons().find('.announcement_module__buttons__button__next')

  get_nav_buttons_markup: -> $("
      <div class='announcement_module__buttons__nav' />
    ").append(@get_prev_button_markup()).append(@get_next_button_markup())

  resize_flickity: -> @flickity.resize() if @flickity

  set_nav_index: (index) ->
    if @flickity.slides.length <= 1
      @get_prev_button().addClass('announcement_module__buttons__button__inactive')
      @get_next_button().addClass('announcement_module__buttons__button__inactive')
    else
      @get_prev_button().toggleClass('announcement_module__buttons__button__inactive', (index == 0))
      @get_next_button().toggleClass('announcement_module__buttons__button__inactive', (index >= @flickity.slides.length - 1))

Carousel.register()
