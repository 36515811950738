import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'MapModule__Loader'

  @selector: ".map_module"

  on_init: ->
    `import('./map_module' /* webpackChunkName: "map_module" */)`

Loader.register()
