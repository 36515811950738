import Plugin from 'lib/modulor/plugin'

export class Overlay extends Plugin
  @defaults =
    debug: false
    name: 'Overlay'

  @selector: ".overlay:not([data-new])"

  on_init: ->
    @$element.on "click.#{@options.name}", (e) => @close()
    @$element.on "click.#{@options.name}", ".overlay__close", (e) => @close()

  on_keyup: (e) ->
    if e.keyCode == 27
      @close()

  close: -> @$element.remove()

Overlay.register()
