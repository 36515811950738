# ScrollIntoView.call(el)

# TODO: replace with native 
# https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
# https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded

import _get from 'lodash/get'
import Plugin from '../plugin'
import inView from 'in-view'

export default class ScrollIntoView extends Plugin
  @call = (el, options = {}, callback) ->
    $element = $(el)

    always = _get(options, 'always', false)
    offset = _get(options, 'offset', 0)
    partially = _get(options, 'partially', false)
    speed = _get(options, 'speed', 250)

    if partially then inView.threshold(1) else inView.threshold(0.25)
    return false if inView.is($element[0])

    $('html, body').animate(
      scrollTop: ($element.offset().top + offset),
      speed,
      callback
    )
