import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'SoftTabsContainerModule__Loader'

  @selector: ".soft_tabs_container_module"

  on_init: ->
    `import('./soft_tabs_container_module' /* webpackChunkName: "soft_tabs_container_module" */)`

Loader.register()
