import Plugin from 'lib/modulor/plugin'

export class Caption extends Plugin
  @defaults =
    debug: false
    name: 'Overlay__Caption'

  @selector: ".overlay__caption"

  on_init: -> @set_height()
  on_resize: -> @set_height()

  set_height: -> @$element.css('height', "#{window.innerHeight}px")

# OLD
# Caption.register()
