import Page from './page'

export default class Page__IsEdited extends Page
  @defaults =
    debug: false
    name: 'Modulor__Page__IsEdited'

  @selector: ".modulor_page__is_edited"

  # ---------------------------------------------------------------------

  on_init: ->
    @beforeunload_handler = (event) =>
      if @is_any_web_module_edited()
        if event.type == 'beforeunload' # non-turbolinks
          event.returnValue = @get_confirmation_message()
          event.returnValue
        else # turbolinks
          confirm(@get_confirmation_message())

    $(window).bind 'beforeunload', @beforeunload_handler
    $(document).bind 'turbolinks:before-visit', @beforeunload_handler

    @$element.on "update_web_module_positions.#{@options.name}", (e) =>
      e.stopPropagation()
      @update_web_module_positions()

  on_destroy: ->
    $(window).off 'beforeunload', @beforeunload_handler if !!@beforeunload_handler
    $(document).off 'turbolinks:before-visit', @beforeunload_handler if !!@beforeunload_handler

  # ---------------------------------------------------------------------

  get_update_positions_path: -> @$element.data 'update-positions-path'
  get_confirmation_message: -> @$element.data('confirmation-message')

  # ---------------------------------------------------------------------

  is_any_web_module_edited: -> @get_web_modules().filter(".modulor_web_module__is_edited").length > 0

  # ---------------------------------------------------------------------

  update_web_module_positions: () ->
    $web_modules = @get_web_modules().not(".modulor_dummy_module")
    web_module_positions = $.map $web_modules, (wm, i) -> $(wm).data('id')
    $.post
      dataType: 'json'
      url: @get_update_positions_path()
      data:
        _method: 'PUT'
        web_module_positions: web_module_positions

Page__IsEdited.register()
