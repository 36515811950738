import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class OverlayNew extends Plugin
  @defaults =
    debug: false
    name: 'OverlayNew'

  @selector: ".overlay[data-new]"

  @property "svgTarget",
    get: ->
      switch @viewOption
        when "mask" then @element.querySelector(".overlay__svg__mask")
        when "typography" then @element.querySelector(".overlay__svg__typography")

  @property "imgTarget",
    get: -> @element.querySelector(".overlay__img")

  @property "maskTarget",
    get: -> @element.querySelector(".overlay__mask")

  @property "pathTargets",
    get: -> @svgTarget.querySelectorAll(".overlay__path")

  @property "rectTarget",
    get: -> @element.querySelector(".overlay__rect")

  @property "svgTargetComputedStyle",
    get: -> window.getComputedStyle(@svgTarget)

  @property "innerWidth",
    get: -> @element.offsetWidth

  @property "innerHeight",
    get: -> @element.offsetHeight

  @property "hideTimeoutDuration",
    get: -> 3000

  @property "duration",
    get: -> 700

  # true or false
  @property "expanded",
    get: -> @element.hasAttribute("data-expanded")
    set: (val) ->
      @element.toggleAttribute("data-expanded", val)
      if val
        @resizePath()
        @resizeRect()

  # mask or typography
  @property "viewOption",
    get: -> @element.getAttribute("data-view-option")
    set: (val) -> @element.setAttribute("data-view-option", val)

  # permanent or onclick
  @property "stateOption",
    get: -> @element.getAttribute("data-state-option")
    set: (val) -> @element.setAttribute("data-state-option", val)

  @property "currentPage",
    get: -> document.querySelector("[class*='__is_current'][data-page-type]")

  @property "currentPageImgs",
    get: -> @currentPage?.querySelectorAll("img[data-aspect-ratio]") || []

  @property "randomImg",
    get: ->
      imgs = @currentPageImgs
      imgs[Math.floor(Math.random() * imgs.length)]

  on_init: ->
    @observer = new MutationObserver(@handleMutations.bind(@))
    @observer.observe(@element, attributes: true)

    switch @stateOption
      when "permanent"
        @$element.one "click.#{@options.name}", @onClickClose.bind(@)

        if img = @randomImg
          @viewOption = "mask"
          @assignAndLoadImage(img)
        else
          @viewOption = "typography"

        @hideTimeout = setTimeout(@hide.bind(@), @hideTimeoutDuration)

      when "onclick"
        @$element.one "click.#{@options.name}", "a[href^='/']", @onClickLink.bind(@)

    @setMaskBgColor()
    @resizePath()
    @resizeRect()

  on_destroy: ->
    @observer.disconnect() if @observer
    clearTimeout(@hideTimeout) if @hideTimeout

  on_resize: ->
    @resizePath()
    @resizeRect()

  onClickClose: (e) ->
    return unless @expanded
    clearTimeout(@hideTimeout) if @hideTimeout
    @close()

  onClickLink: (e) ->
    e.stopImmediatePropagation()
    e.preventDefault()

    if img = e.currentTarget.querySelector("img[data-aspect-ratio]")
      @viewOption = "mask"
      @assignAndLoadImage(img)
      @reveal()

    # do not display typography only
    # else
      # @viewOption = "typography"
      # @reveal()

    href = e.currentTarget.getAttribute("href")

    setTimeout (=>
      Turbolinks.visit(href)
    ), @duration

  handleMutations: (mutations, observer) ->
    for mutation in mutations
      if mutation.type == "attributes" && mutation.attributeName == "data-view-option"
        @resizePath()
        @resizeRect()

  assignAndLoadImage: (img) ->
    @imgTarget.setAttribute("src", img.getAttribute("src"))
    # @imgTarget.setAttribute("srcset", img.getAttribute("srcset"))
    @imgTarget.setAttribute("data-src", img.getAttribute("data-src"))
    @imgTarget.setAttribute("data-srcset", img.getAttribute("data-srcset"))
    @imgTarget.classList.add("lazyload")
    lazySizes.autoSizer.checkElems()

  resetImage: ->
    @imgTarget.setAttribute("class", "overlay__img")
    @imgTarget.setAttribute("src", "")
    @imgTarget.setAttribute("srcset", "")
    @imgTarget.setAttribute("data-src", "")
    @imgTarget.setAttribute("data-srcset", "")

  setMaskBgColor: ->
    computedStyle = window.getComputedStyle(document.body)
    bgColor = computedStyle.backgroundColor
    @maskTarget.style.backgroundColor = switch bgColor
                                        when "rgba(0, 0, 0, 0)" then "white"
                                        else bgColor

  resizePath: ->
    for pathTarget in @pathTargets
      align = pathTarget.getAttribute("data-align") || "center"

      pathTargetWidth = pathTarget.getBBox().width
      pathTargetHeight = pathTarget.getBBox().height

      scale = @innerHeight / pathTargetHeight

      translateX = switch align
                    when "left" then 0
                    when "right" then @innerWidth - (pathTargetWidth * scale)
                    else (@innerWidth - (pathTargetWidth * scale)) / 2

      translateY = 0

      pathTarget.setAttribute("transform", "translate(#{translateX}, #{translateY}) scale(#{scale}, #{scale})");

  resizeRect: ->
    @rectTarget.setAttribute("width", @innerWidth)
    @rectTarget.setAttribute("height", @innerHeight)

  reveal: ->
    scrollY = window.pageYOffset

    @expanded = true

    @maskTarget.scrollTop = scrollY

    switch @viewOption
      when "mask" then @rectTarget.style.transform = "translateY(#{@innerHeight}px)"
      when "typography" then @svgTarget.style.clipPath = "inset(0 0 0 0)"

  hide: ->
    switch @viewOption
      when "mask"
        @rectTarget.style.display = "initial"
        @rectTarget.style.transform = "translateY(-1%)"
      when "typography" then @svgTarget.style.clipPath = "inset(0 0 100% 0)"

    setTimeout(@close.bind(@), @duration)

  # on_keyup: (e) ->
  #   return unless @expanded
  #   if e.keyCode == 27
  #     @close()

  close: ->
    @expanded = false
    @stateOption = "onclick"
    @resetImage()
    @$element.one "click.#{@options.name}", "a[href^='/']", @onClickLink.bind(@)

OverlayNew.register()
