export default class R
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    p1 = new Path2D(
      "
      M #{0 * scaleRatio + offsetX}, #{510 * scaleRatio + offsetY + ctx.lineWidth / 2.0}
      h #{242 * scaleRatio}
      c #{185 * scaleRatio}, #{0 * scaleRatio}, #{348 * scaleRatio} #{-30 * scaleRatio}, #{348 * scaleRatio} #{-253 * scaleRatio}
      c #{0 * scaleRatio} #{-66 * scaleRatio} #{-11 * scaleRatio} #{-123 * scaleRatio} #{-57 * scaleRatio} #{-173 * scaleRatio}
      C #{447 * scaleRatio + offsetX} #{-7 * scaleRatio + offsetY + ctx.lineWidth / 2.0}, #{314 * scaleRatio + offsetX}, #{0 * scaleRatio + offsetY + ctx.lineWidth / 2.0}, #{199 * scaleRatio + offsetX}, #{0 * scaleRatio + offsetY + ctx.lineWidth / 2.0}
      L #{0 * scaleRatio + offsetX}, #{0 * scaleRatio + offsetY + ctx.lineWidth / 2.0}
      v #{1000 * scaleRatio}
      "
    )

    p2 = new Path2D(
      "
      M #{621 * scaleRatio + offsetX}, #{1010 * scaleRatio + offsetY}
      c #{-27 * scaleRatio} #{-79 * scaleRatio} #{-56 * scaleRatio} #{-199 * scaleRatio} #{-68 * scaleRatio} #{-274 * scaleRatio}
      c #{-16 * scaleRatio} #{-99 * scaleRatio} #{-22 * scaleRatio} #{-174 * scaleRatio} #{-133 * scaleRatio} #{-210 * scaleRatio}
      c #{-53 * scaleRatio} #{-18 * scaleRatio} #{-120 * scaleRatio} #{-16 * scaleRatio} #{-176 * scaleRatio} #{-16 * scaleRatio}
      "
    )

    # M (x,y)
    # m (dx,dy)
    # L (x, y)
    # l (dx, dy)
    # c (dx1,dy1, dx2,dy2, dx,dy)
    # C (x1,y1, x2,y2, x,y)
    # v dy
    # H x
    # h dx

    ctx.stroke(p1)
    ctx.stroke(p2)

  getWidth: (scaleRatio) ->
    621 * scaleRatio
