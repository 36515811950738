import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./crud_resources' /* webpackChunkName: "crud_resources" */)`,
  loading: -> <Loading />

export default class LoadableCrudResources extends Component
  render: -> <LoadableComponent {...this.props} />
