import _debounce from 'lodash/debounce'
import MutationObserver from 'lib/modulor/mutation_observer'

export default class Plugin
  @register: ->
    klass = this
    defaults = this.defaults ? {}
    name = defaults.name or= /function ([^(]*)/.exec( klass+"" )[1] # WARNING: this corresponds to the class, and does not respect namespace!
    options = Array.prototype.slice.call(arguments)[1..]
    selector = arguments[0] ? klass.selector

    @init_plugin(klass, name)

    MutationObserver.register(
      selector,
      ((el) -> $(el)[name](options...)),
      ((el) -> $(el)[name]('destroy'))
    )

    $(selector).each (i, el) -> $(el)[name](options...)

  # TODO @unregister: ->

  @init_plugin: (klass, name) ->
    $.fn[name] = (options) ->
      args = arguments
      dataKey = "plugin_#{name}"

      if options is `undefined` or typeof options is "object"
        @each ->
          @.pluginInstances or= {}
          unless @.pluginInstances[dataKey]
            instance = new klass(@, options)
            @.pluginInstances[dataKey] = instance

      else if (typeof options is 'string') and (options[0] isnt '_') and (options isnt 'init')
        returns = undefined
        @each ->
          @.pluginInstances or= {}
          instance = @.pluginInstances[dataKey]
          returns = instance[options].apply(instance, Array::slice.call(args, 1)) if (instance instanceof klass) and (typeof instance[options] is 'function')
          @.pluginInstances[dataKey] = null if options is 'destroy'
        if (returns isnt `undefined`) then returns else @

  constructor: (@element, options) ->
    @options = $.extend {}, @constructor.defaults, options
    @$element = $(@element)
    @init()

  init: ->
    return if @_is_init == true
    @_is_init = true

    @turbolinks_before_cache_handler = => @destroy()
    $(document).on 'turbolinks:before-cache', @turbolinks_before_cache_handler

    if typeof @on_turbolinks_render == 'function'
      @turbolinks_render_handler = => @on_turbolinks_render()
      $(document).on 'turbolinks:render', @turbolinks_render_handler

    if typeof @on_load == 'function'
      @load_handler = => @on_load()
      $(window).on 'load', @load_handler

    if typeof @on_ready == 'function'
      @ready_handler = => @on_ready()
      $(document).on 'ready', @ready_handler

    if typeof @on_keyup == 'function'
      @keyup_handler = (e) => @on_keyup(e)
      $(document).on 'keyup', @keyup_handler

    if typeof @on_resize == 'function'
      @resize_handler = _debounce(@on_resize.bind(this), @options.debounce ? 50)
      $(window).on 'resize', @resize_handler

    @on_init()

  destroy: ->
    return if @_is_destroy == true
    @_is_destroy = true

    # always remove all namespaced bound events
    @$element.off ".#{@options.name}"

    $(document).off 'turbolinks:render', @turbolinks_render_handler if @turbolinks_render_handler
    $(document).off 'turbolinks:before-cache', @turbolinks_before_cache_handler if @turbolinks_before_cache_handler
    $(document).off 'ready', @ready_handler if @ready_handler
    $(window).off 'load', @load_handler if @load_handler

    $(document).off 'keyup', @keyup_handler if @keyup_handler
    $(window).off 'resize', @resize_handler if @resize_handler

    @on_destroy()

    @element.pluginInstances['plugin_'+@options.name] = null
    @element = undefined
    @$element = undefined

  on_init: ->
  on_destroy: ->

  # on_load: ->
  # on_ready: ->
  # on_turbolinks_render: ->

  # on_resize: ->
  # on_keyup: (e) ->
