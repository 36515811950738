import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'ParentPathInput__Loader'

  @selector: ".modulor_parent_path_input"

  on_init: ->
    `import('./parent_path_input_chunk' /* webpackChunkName: "modulor_parent_path_input" */)`

Loader.register()
