import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'ProfileModule__Loader'

  @selector: ".profile_module"

  on_init: ->
    `import('./profile_module' /* webpackChunkName: "profile_module" */)`

Loader.register()
