import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'SimpleFormDependentFields__Wrapper'

  @selector: ".simple_form_dependent_fields"

  on_init: ->
    { default: SimpleFormDependentFields } = await `import('./simple_form_dependent_fields' /* webpackChunkName: "simple_form_dependent_fields" */)`
    @plugin_instance = new SimpleFormDependentFields(@element)

  on_destroy: ->
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()
