import D from './characters/D'
import E from './characters/E'
import F from './characters/F'
import H from './characters/H'
import K from './characters/K'
import L from './characters/L'
import M from './characters/M'
import N from './characters/N'
import R from './characters/R'
import U from './characters/U'
import W from './characters/W'

export default class Character
  @defaults =
    debug: false
    color: 'rgb(0,0,0)'
    lineWidth: 12
    margin: 100

  constructor: (name, options = {}) ->
    @name = name
    @options = Object.assign {}, @constructor.defaults, options

    @character = switch @name
      when 'D' then new D
      when 'E' then new E
      when 'F' then new F
      when 'H' then new H
      when 'K' then new K
      when 'L' then new L
      when 'M' then new M
      when 'N' then new N
      when 'R' then new R
      when 'U' then new U
      when 'W' then new W
    @canvas = document.createElement('canvas')
    @width
    @height
    @scaleRatio = 1.0

  destroy: ->
    @canvas.remove()

  setDimensions: (width, height, lineWidth) ->
    return unless (@width != width) || (@height != height) || (@options.lineWidth != lineWidth)

    @width = width
    @height = height
    @options.lineWidth = lineWidth

    @scaleRatio = height / 1000.0
    @margin = Math.max(width, height) / 2

    @canvas.width = @width + @margin * 2
    @canvas.height = @height + @margin * 2

    ctx = @getContext()

    ctx.strokeStyle = @options.color
    ctx.lineWidth = @options.lineWidth * @options.dpi
    # ctx.lineCap = 'butt'
    # ctx.lineJoin = 'miter'
    # ctx.miterLimit = 4

    @character.render(ctx, @scaleRatio, @options.margin, @options.margin)

  getCanvas: -> @canvas
  getContext: -> @canvas.getContext('2d')
  getLineWidth: -> @options.lineWidth * @options.dpi
  getMargin: -> @options.margin
  getWidth: -> @character.getWidth(@scaleRatio)

  drawImage: (ctx, x, y) ->
    ctx.drawImage(@canvas, Math.round(x - @options.margin), Math.round(y - @options.margin))
