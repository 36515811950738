import DateQueries from './date_queries'

export default class Exhibition extends DateQueries
  @defaults =
    debug: false
    name: 'DateQueries__Exhibition'

  @selector: ".date_queries__exhibition"

Exhibition.register()
