import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./google_map' /* webpackChunkName: "google_map" */)`,
  loading: -> <Loading />

export default class LoadableGoogleMap extends Component
  render: -> <LoadableComponent {...this.props} />
