(($) ->

  _swap = (a, b, callback) ->
    from = $(a)
    dest = $(b)

    animation_speed = 300

    from_pos = from.offset()
    dest_pos = dest.offset()

    from_clone = from.clone()
    dest_clone = dest.clone()

    direction = if from_pos.top > dest_pos.top then 'up' else 'down'

    total_route = dest_pos.top + dest.height() - from_pos.top

    route_from_vertical = 0
    route_dest_vertical = 0

    from.css 'opacity', 0
    dest.css 'opacity', 0

    from_clone.insertAfter(from).css(
      position: 'absolute'
      width: from.outerWidth()
      height: from.outerHeight()).offset(from_pos).css 'z-index', '999'

    dest_clone.insertAfter(dest).css(
      position: 'absolute'
      width: dest.outerWidth()
      height: dest.outerHeight()).offset(dest_pos).css 'z-index', '999'

    if direction == 'down'
      route_dest_vertical = total_route - dest.height()
      route_from_vertical = total_route - from.height()
    else
      route_dest_vertical = total_route - from.height()
      route_from_vertical = total_route - dest.height()

    from_clone.animate {
      top: '+=' + route_from_vertical + 'px'
    }, animation_speed, ->
      dest.insertBefore(this).css 'opacity', 1
      $(this).remove()

    dest_clone.animate {
      top: '-=' + route_dest_vertical + 'px'
    }, animation_speed, ->
      from.insertBefore(this).css 'opacity', 1
      $(this).remove()
      callback.call(self) if callback
    from

  $.fn.swap = (a, b) ->
    options = $.extend({ callback: -> }, arguments[1] or {})

    t = this
    if t.length == 1 and a.length == 1
      return _swap(t, a, options.callback)
    else
      $.error 'Argument Error!'
    return

  return
) jQuery
