import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Check extends Plugin
  @defaults =
    debug: false
    name: 'Overlay__Check'

  @selector: ".modulor_page__is_master[data-permalink='Site']"

  @property "shouldNotInjectOldOverlay",
    get: -> document.body.getAttribute('data-path').includes("Index-New")

  on_init: ->
    return if @is_edit_mode()
    return if @shouldNotInjectOldOverlay
    @inject_overlay()

  get_images: ->
    current_path = @get_current_path()

    @get_current_page()
      .find('.modulor_attachment[data-file-type="image"]')
      .find('img[data-aspect-ratio]')
      .not('.overlay__ignore')
      .filter (i, el) ->
        return true unless current_path == 'Visit'
        return false if $(el).closest('.edition_list_module__content[data-view-option="selected"]').length > 0
        return false if $(el).closest('.publication_list_module__content[data-view-option="selected"]').length > 0
        true

  get_current_page: -> $('.modulor_page__is_current')
  get_current_path: -> $('body').data('path')

  get_captions: ->
    @get_images().map (i, el) ->
      if $figure = $(el).closest('figure')
        $figure.find('figcaption').first()

  get_canvas_markup: ->
    """
    <canvas class='overlay__canvas'/>
    """

  get_caption_markup: (caption) ->
    """
      <div class='overlay__caption'>
        <div>#{caption}</div>
      </div>
    """

  get_overlay: -> $('.overlay')

  get_overlay_markup: ->
    $markup = $("""
      <div class='overlay'>
        <div class='overlay__captions'></div>
        <button class='overlay__close'>
          <svg viewBox='0 0 100 100'>
            <line x1='0' y1='0' x2='100' y2='100' />
            <line x1='100' y1='0' x2='0' y2='100' />
          </svg>
        </button>
      </div>
    """)

    $markup.prepend(@get_canvas_markup())

    $captions = $markup.find('.overlay__captions')
    @get_captions().each (i, el) =>
      caption = $(el).html() || ""
      $captions.append @get_caption_markup(caption)

    $markup

  is_edit_mode: -> $('body').hasClass('is_edit_mode')

  inject_overlay: ->
    return if @get_overlay().length
    $('body').append @get_overlay_markup()
    @get_overlay().focus()

  remove_overlay: ->
    @get_overlay().remove()

# OLD
# Check.register()
