import ToDialog from 'lib/modulor/to_dialog/to_dialog'

export default class Form__ToDialog extends ToDialog
  @defaults =
    debug: false
    delay: 10
    name: 'Modulor__Form__ToDialog'

  @selector: ".modulor_form__to_dialog"

  on_init: ->
    super()

    @$element.on "change.#{@options.name}", (e) =>
      return unless @get_selected_value().length > 0
      @show_dialog()
      setTimeout (=> @load_dialog_content()), @options.delay # wait for the dialog to init

  # ---------------------------------------------------------------------

  get_selected_text: -> @get_select().find(':selected').text()
  get_selected_value: -> @get_select().find(':selected').val()
  get_select: -> @$element.find('select')

  # overrides
  get_dialog_title: -> @$element.data('title-template').replace('%{model_name}', @get_selected_text())
  get_href: -> @$element.prop('action') + '?' + @$element.serialize()

  # ---------------------------------------------------------------------

  handleDialogContentLoaded: (content) ->
    super(content)
    @reset_select()

  # ---------------------------------------------------------------------

  reset_select: -> @get_select().val('')

Form__ToDialog.register()
