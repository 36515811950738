import 'shared/modulor/semantic_date_time_tags'
import Plugin from 'lib/modulor/plugin'

export class SemanticDateRange extends Plugin
  @defaults =
    debug: false
    name: 'DateRange'

  @selector: ".date_range"

  on_init: ->
    { default: @moment } = await `import('moment' /* webpackChunkName: "moment" */)`
    return unless @element
    return unless @is_date_range()
    @set_current_year_class()
    @set_past_class()

  get_semantic_times: -> @$element.find('time.semantic')

  is_current_year: -> @get_semantic_times().length == @get_semantic_times().filter('.current_year').length
  is_date_range: -> @get_semantic_times().length >= 2
  is_past: -> @get_semantic_times().length == @get_semantic_times().filter('.past').length

  set_current_year_class: -> @$element.toggleClass('current_year', @is_current_year())
  set_past_class: -> @$element.toggleClass('past', @is_past())

SemanticDateRange.register()
