# export class SiteMenu extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'SiteMenuModule'
#
#   @selector: ".site_menu_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# SiteMenu.register()
