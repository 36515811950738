class Details extends HTMLElement
  constructor: ->
    super()

    @_handle_title_click = @handle_title_click.bind(this)

    template = document.createElement('template')
    template.innerHTML = """
      <style>
        :host {
          display: block;
        }

        dl, dt, dd {
          all: unset;
          display: block;
        }

        .summary {
          cursor: pointer;
          user-select: none;
        }

        .details {
          height: 0px;
          overflow: hidden;
          transition-property: height;
          transition-duration: var(--transition-duration, 0.25s);
          transition-timing-function: var(--transition-timing-function, ease-in-out);
        }
      </style>

      <dl>
        <dt class='summary'>
          <slot name='summary'>TITLE</slot>
        </dt>

        <dd class='details'>
          <slot name='details'>BODY</slot>
        </dd>
      </dl>
    """

    @attachShadow(mode: 'open')
    @shadowRoot.appendChild(template.content.cloneNode(true))

    @setAttribute('aria-expanded', false)

  connectedCallback: ->
    @get_summary().addEventListener "click", @_handle_title_click

  disconnectedCallback: ->
    @get_summary().removeEventListener "click", @_handle_title_click

  handle_title_click: (e) ->
    if @is_expanded()
      @get_details().style.height = 0 + "px"
      @setAttribute('aria-expanded', 'false')
    else
      @get_details().style.height = @get_details().scrollHeight + "px"
      @setAttribute('aria-expanded', 'true')

  is_expanded: -> @getAttribute('aria-expanded') == 'true'

  get_summary: -> @shadowRoot.querySelector(".summary")
  get_details: -> @shadowRoot.querySelector(".details")

window.customElements.define('modulor-details', Details)
