import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'Modulor::Lock::OwnerFormObject__Loader'

  @selector: ".modulor_lock_owner_form_object"

  on_init: ->
    `import('./lock_owner_form_object' /* webpackChunkName: "modulor_lock_owner_form_object" */)`

Loader.register()
