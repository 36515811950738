import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'AnnouncementModule__Content'

  @selector: ".announcement_module__content"

  on_init: ->
    return unless @get_attachments().length > 1

    { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new Flickity(@get_container()[0],
      adaptiveHeight: false
      cellAlign: 'left'
      cellSelector: ".announcement_module__attachment_image"
      initialIndex: 0
      pageDots: true
      prevNextButtons: false
    )

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'

  get_container: -> @$element.find(".announcement_module__attachment_images")
  get_attachments: -> @$element.find(".announcement_module__attachment_image")

Content.register()
