import _debounce from 'lodash/debounce'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    debounce: 100
    name: 'PublicationListModule__Content'

  @selector: ".publication_list_module__content"

  on_init: ->
    # return unless @get_thumbnails().length > 1
    #
    # { default: Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`
    #
    # @flickity = new Flickity(@get_thumbnails_container()[0],
    #   adaptiveHeight: false
    #   cellAlign: 'left'
    #   cellSelector: ".publication_page__thumbnail"
    #   groupCells: "100%"
    #   initialIndex: 0
    #   pageDots: false
    #   prevNextButtons: false
    # )
    #
    # @font_load_handler = _debounce(@resize_flickity.bind(this), @options.debounce)
    # $('html').on "font-load.#{@options.name}", @font_load_handler

  on_destroy: ->
    # @flickity.destroy() if @flickity
    # @$element.off '.flickity'
    # $('html').off('font-load', @font_load_handler) if @font_load_handler

  get_thumbnails_container: -> @$element.find(".publication_list_module__thumbnails")
  get_thumbnails: -> @$element.find(".publication_page__thumbnail")

  resize_flickity: ->
    return unless @flickity
    @flickity.resize()

Content.register()
