import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'Dialog__Loader'

  @selector: ".modulor_dialog"

  on_init: ->
    `import('./dialog_chunk' /* webpackChunkName: "modulor_dialog" */)`

Loader.register()
