import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'CrudResourceListModule__Loader'

  @selector: ".crud_resource_list_module"

  on_init: ->
    `import('./crud_resource_list_module' /* webpackChunkName: "crud_resource_list_module" */)`

Loader.register()
