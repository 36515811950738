# export class EditionList extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'EditionListModule'
#
#   @selector: ".edition_list_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# EditionList.register()
