import Plugin from 'lib/modulor/plugin'

export default class Tab extends Plugin
  @defaults =
    debug: false
    name: "PressRegistrationModule__Tab"

  @selector: '.press_registration_module__tab'

  on_init: ->
    @$element.on "click.#{@options.name}", '.press_registration_module__tab__summary', (e) =>
      @toggle_expanded()

  get_body: -> @$element.find('.press_registration_module__tab__body')
  get_button: -> @get_summary().find 'button'
  get_summary: -> @$element.find '.press_registration_module__tab__summary'

  toggle_expanded: ->
    $button = @get_button()
    $body = @get_body()

    if $button.attr('aria-expanded') == 'true'
      $button.attr('aria-expanded', 'false')
      $body.attr('aria-hidden', 'true')
    else
      $button.attr('aria-expanded', 'true')
      $body.attr('aria-hidden', 'false')

Tab.register()
