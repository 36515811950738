export default class L
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    ctx.moveTo(
      Math.round(573 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY - ctx.lineWidth / 2.0)
    )

    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY - ctx.lineWidth / 2.0)
    )

    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(0 * scaleRatio + offsetY)
    )

    ctx.stroke()

  getWidth: (scaleRatio) ->
    573.5 * scaleRatio
