import Plugin from 'lib/modulor/plugin'

export default class DateTimePickerInput extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__DateTimePickerInput'

  @selector: ".date_time_picker.input"

  on_init: ->
    @toggle_all_day()
    @$element.on "change.#{@options.name}", ".modulor_date_picker__input, .modulor_time_picker__input", (e) => @update_value()
    @get_all_day_input().on "change.#{@options.name}", (e) => @toggle_all_day()

  on_destroy: ->
    @get_all_day_input().off ".#{@options.name}"

  get_all_day_input: -> @$element.siblings("div.input.boolean[class*='all_day']").find("input[type='checkbox'][name*='all_day']")
  get_date_picker_input: -> @$element.find ".modulor_date_picker__input"
  get_date_time_picker_input: -> @$element.find 'input.date_time_picker'
  get_time_picker_input: -> @$element.find ".modulor_time_picker__input"

  get_date_val: -> @get_date_picker_input().val()
  get_time_val: -> @get_time_picker_input().val()

  is_all_day: -> @get_all_day_input().is ':checked'

  update_value: ->
    @get_date_time_picker_input().val([@get_date_val(), @get_time_val()].join(' '))

  toggle_all_day: ->
    return unless !!@get_all_day_input().length
    @get_time_picker_input().toggle !@is_all_day()

DateTimePickerInput.register()
