import Plugin from 'lib/modulor/plugin'

export default class Archive extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Page__Archive'

  @selector: ".modulor_page_archive"

Archive.register()
