import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'PageListModule__Loader'

  @selector: ".page_list_module"

  on_init: ->
    `import('./page_list_module' /* webpackChunkName: "page_list_module" */)`

Loader.register()
