import Plugin from 'lib/modulor/plugin'

import { isPast, parse } from 'date-fns'

export class DateRange extends Plugin
  @defaults =
    debug: false
    name: 'EventPage__Thumbnail__DateRange'

  @selector: ".event_page__thumbnail__date_range"

  on_init: ->
    return unless isPast(@get_to())
    @$element.addClass('event_page__thumbnail__date_range__past')

  on_destroy: ->
    @$element.removeClass('event_page__thumbnail__date_range__past')

  get_from: -> parse @$element.find('.semantic.from').attr('datetime')
  get_to: -> parse @$element.find('.semantic.to').attr('datetime')

DateRange.register()
