# import Plugin from 'lib/modulor/plugin'

# import AspectRatioThumbnail from 'shared/aspect_ratio_thumbnail/aspect_ratio_thumbnail'

# export class Image extends AspectRatioThumbnail
#   @defaults =
#     debug: false
#     name: 'EventPage__Thumbnail__Image'

#   @selector: ".event_page__thumbnail__image"

# Image.register()
