import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'Dropdown__Loader'

  @selector: ".dropdown"

  on_init: ->
    `import('./dropdown_chunk' /* webpackChunkName: "dropdown" */)`

Loader.register()
