import Plugin from 'lib/modulor/plugin'

import { isPast, parse } from 'date-fns'

export class DailyOccurrence extends Plugin
  @defaults =
    debug: false
    name: 'EventHeaderModule__Heading__DailyOccurrence'

  @selector: ".event_header_module__heading__daily_occurrence"

  on_init: ->
    return unless isPast(@get_to())
    @$element.addClass('event_header_module__heading__daily_occurrence__past')

  on_destroy: ->
    @$element.removeClass('event_header_module__heading__daily_occurrence__past')

  get_from: -> parse @$element.find('.semantic.from').attr('datetime')
  get_to: -> parse @$element.find('.semantic.to').attr('datetime')

DailyOccurrence.register()
