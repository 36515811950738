import Dialog from 'shared/modulor/dialog/dialog'
import Plugin from 'lib/modulor/plugin'

export default class ToDialog extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__ToDialog'

  on_init: ->
    @$element.on "close_dialog.#{@options.name}", (e) =>
      e.stopPropagation()
      @get_dialog().trigger 'close_dialog'

    @$element.on "replace_content.#{@options.name}", (e) =>
      e.stopPropagation()
      @replace_dialog_content(e.detail.content)

    @$element.on "create.#{@options.name} update.#{@options.name}", (e) =>
      if Object.keys(JSON.parse(e.detail.errors)).length > 0
        @replace_dialog_content(e.detail.html)
      else
        @get_dialog().trigger 'close_dialog'

  get_dialog_class: -> @$element.data 'dialog-class'
  get_dialog_title: -> @$element.prop('title') || @$element.data('original-title')
  get_dialog: -> $(Dialog.selector + "[data-id='#{@get_dialog_id()}'][data-action='#{@get_action()}']")
  get_href: -> if @$element.is('input') then @$element.closest('form').prop('action') else @$element.attr 'href'
  get_dialog_id: -> @get_resource_id() || @get_resource_type()
  get_resource_id: -> @$element.data('resource-id')
  get_resource_type: -> @$element.data('resource-type')
  get_action: -> @$element.data('action')

  handleDialogContentLoaded: (content) -> @replace_dialog_content(content)

  show_dialog: -> Dialog.build(
    title: @get_dialog_title(),
    class: @get_dialog_class(),
    attr: {
      'data-id': @get_dialog_id(),
      'data-resource-id': @get_resource_id(),
      'data-resource-type': @get_resource_type(),
      'data-action': @get_action()
    }
  )

  load_dialog_content: ->
    @get_dialog().trigger "show_loading"
    $.ajax
      dataType: 'html'
      url: @get_href()
      type: 'GET'
      success: (content) => @handleDialogContentLoaded(content)

  replace_dialog_content: (content) ->
    event = new CustomEvent('replace_content',
      bubbles: false
      detail:
        content: content
    )

    for el in document.querySelectorAll(Dialog.selector + "[data-id='#{@get_dialog_id()}'][data-action='#{@get_action()}']")
      el.dispatchEvent(event)
