import DateQueries from './date_queries'

export default class Event extends DateQueries
  @defaults =
    debug: false
    name: 'DateQueries__Event'

  @selector: ".date_queries__event"

Event.register()
