# export class ExhibitionHeader extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'ExhibitionHeaderModule'
#
#   @selector: ".exhibition_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# ExhibitionHeader.register()
