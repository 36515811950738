import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'NoteModule__Loader'

  @selector: ".note_module"

  on_init: ->
    `import('./note_module' /* webpackChunkName: "note_module" */)`

Loader.register()
