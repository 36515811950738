import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'InstagramModule__Loader'

  @selector: ".instagram_module"

  on_init: ->
    `import('./instagram_module' /* webpackChunkName: "instagram_module" */)`

Loader.register()
