import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'RecurringSelect__Wrapper'

  @selector: ".recurring_select"

  on_init: ->
    { default: RecurringSelect } = await `import('./recurring_select' /* webpackChunkName: "recurring_select" */)`
    @plugin_instance = new RecurringSelect(@element)
    @$element.on 'focus.RecurringSelect__Wrapper', (e) => @plugin_instance.set_initial_values()
    @$element.on 'change.RecurringSelect__Wrapper', (e) => @plugin_instance.changed()

  on_destroy: ->
    @$element.off '.RecurringSelect__Wrapper'
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()
