import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'ChartModule__Loader'

  @selector: ".chart_module"

  on_init: ->
    `import('./chart_module' /* webpackChunkName: "chart_module" */)`

Loader.register()
