import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./column_module_width_input' /* webpackChunkName: "column_module_width_input" */)`,
  loading: -> <Loading />

export default class LoadableColumnModuleWidthInput extends Component
  render: -> <LoadableComponent {...this.props} />
