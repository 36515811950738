import Plugin from 'lib/modulor/plugin'

export default class OwnerFormObject extends Plugin
  @defaults =
    action_selector: ".modulor_lock_owner_form_object__action"
    debug: false
    name: "OwnerFormObject"
    subject_type_selector: ".modulor_lock_owner_form_object__subject_type"

  @selector: ["form", ".modulor_lock_owner_form_object"].join('')

  on_init: ->
    @$element.on "click.#{@options.name}", (e) =>
      if $(e.target).is('input[type="checkbox"]')
        $checkbox = $(e.target)
        action = $checkbox.closest(@options.action_selector).data('action')
        subject_type = $checkbox.closest(@options.action_selector).data('subject-type')
        @submit_remotely(@is_persist_immediately(), [action], subject_type)
      else if $(e.target).is('input[type="submit"]')
        $(e.target).prop('disabled', true)
        @submit_remotely(true, @get_actions())

    @get_submit_button().prop('disabled', true)

  get_actions: -> @$element.find("th").filter(".modulor_lock_owner_form_object__action").map((i, e) -> $(e).data('action')).toArray()
  get_loading: -> @$element.find(".modulor_loading")
  get_preview_path: -> @$element.data('preview-path')
  get_subject_types: -> @$element.find(@options.subject_type_selector)
  get_submit_button: -> @$element.find('input[type="submit"]')

  is_persist_immediately: -> @$element.data('persist-immediately') in ['true', true]

  submit_remotely: (persist, actions, subject_type) ->
    @get_loading().show()
    data = @$element.serializeArray()
    data.push({ name: 'actions', value: actions })
    data.push({ name: 'subject_type', value: subject_type }) if !!subject_type
    url = if persist then @$element.attr('action') else @get_preview_path()
    $.ajax(
      data: $.param(data),
      headers: { "modulor-skip-layout": true }, # headers: { "<%= Modulor::HasRequestHeaders::SKIP_LAYOUT %>": true },
      type: 'PUT',
      url: url,
      success: (response) =>
        @get_loading().hide()
        @update_locks(actions, response, persist)
    )

  update_locks: (actions, response, persist) ->
    $response = $(response)
    @get_submit_button().prop('disabled', persist)

    $response.find("td#{@options.action_selector}").each (i, el) =>
      $el = $(el)
      action = $el.data('action')
      subject_type = $el.data('subject-type')
      $old_el = @$element.find("td#{@options.action_selector}").filter("[data-action='#{action}']").filter("[data-subject-type='#{subject_type}']")
      $old_el.replaceWith($el)
      unless persist
        $el.addClass("modulor_lock_owner_form_object__action__is_dirty")

OwnerFormObject.register()
