import MutationObserver from 'lib/modulor/mutation_observer'

MutationObserver.register(
  'textarea:visible.autosize',
  ((el) ->
    { default: autosize } = await `import('autosize' /* webpackChunkName: "autosize" */)`
    setTimeout (() -> autosize(el)), 250
  ),
  ((el) ->
    { default: autosize } = await `import('autosize' /* webpackChunkName: "autosize" */)`
    autosize.destroy(el)
  )
)
