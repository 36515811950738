import Plugin from 'lib/modulor/plugin'

import { parse } from 'date-fns'
import dateQueries from 'date-queries'

export default class DateQueries extends Plugin
  # @defaults =
  #   debug: false
  #   name: 'DateQueries'
  #
  # @selector: '.date_queries'

  on_init: ->
    for { query, label, dtstart, dtend } in @get_params()
      dtstart = parse(dtstart)
      dtend = parse(dtend) if dtend

      if result = dateQueries(dtstart, dtend, [query])
        @$element.html(label)
        break

  on_destroy: ->
    @$element.empty()

  # [
  #   { query: query, label: label, dtstart: dtstart, dtend: null }
  # ]
  get_params: -> @$element.data('params')
