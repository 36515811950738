import { default as WebModule__Content } from 'models/modulor/web_module/content'

import ScrollIntoView from 'lib/modulor/scroll_into_view'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'TextModule__Content'

  @selector: ".text_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", '.text_module__button_to_more', (e) =>
      @toggle_expanded()

    @$element.on "click.#{@options.name}", '.text_module__button_to_less', (e) =>
      @close()

  on_destoy: ->
    @$element.removeAttr('aria-hidden')

  get_input: -> @$element.find('.text_module__button_to_more__input')

  toggle_expanded: ->
    if @get_input().is(':checked')
      @$element.attr('aria-hidden', 'false')
    else
      @$element.attr('aria-hidden', 'true')
      ScrollIntoView.call(@$element, partially: true)

  close: ->
    @$element.removeAttr('aria-hidden')
    @get_input().prop('checked', false)
    ScrollIntoView.call(@$element, partially: true)

Content.register()
