import Plugin from 'lib/modulor/plugin'

export default class ImageGallery extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__ImageGallery'
    counter_selector: ".modulor_image_gallery__counter"
    counter_current_selector: ".modulor_image_gallery__counter__current"
    counter_total_selector: ".modulor_image_gallery__counter__total"
    current_class_name: "modulor_image_gallery__current_slide"
    current_selector: ".modulor_image_gallery__current_slide"
    go_to_slide_selector: ".modulor_image_gallery__go_to_slide"
    inactive_class_name: "modulor_image_gallery__inactive_slide"
    keyboard_nav: false
    next_selector: ".modulor_image_gallery__next"
    prev_selector: ".modulor_image_gallery__prev"
    slide_selector: 'figure'

  on_init: ->
    return unless @has_multiple_images()

    if @options.keyboard_nav
      @keyboard_handler = (e) => @on_key_press(e.keyCode)
      $(document).on 'keydown', @keyboard_handler

    @$element.on "click.#{@options.name}", @options.prev_selector, (e) => @$element.trigger("go_to_prev_slide")
    @$element.on "click.#{@options.name}", @options.next_selector, (e) => @$element.trigger("go_to_next_slide")
    @$element.on "click.#{@options.name}", @options.go_to_slide_selector, (e) => @$element.trigger("go_to_slide", $(e.currentTarget).data('number'))

    @$element.on "go_to_prev_slide.#{@options.name}", (e) => @go_to_prev_slide()
    @$element.on "go_to_next_slide.#{@options.name}", (e) => @go_to_next_slide()
    @$element.on "go_to_slide.#{@options.name}", (e, number) => @go_to_slide(number)

    @init_slides()
    @update_counter()

  on_destroy: ->
    $(document).off('keydown', @keyboard_handler) if !!@keyboard_handler

  # ---------------------------------------------------------------------

  has_multiple_images: -> @get_slides().length > 1

  # ---------------------------------------------------------------------

  get_counter: -> @$element.find @options.counter_selector
  get_current_slide: -> @get_slides().filter(@options.current_selector).first()
  get_initial_slide: ->
    if @get_initial_slide_index() != undefined
      @get_slides().eq(@get_initial_slide_index())
    else
      @get_slides().filter(':visible').first()
  get_initial_slide_index: -> @$element.data('initial-slide')
  get_slides: -> @$element.find(@options.slide_selector)
  get_go_to_slides: -> @$element.find(@options.go_to_slide_selector)

  get_prev_slide: ->
    $prev_slide = @get_current_slide().prev(@options.slide_selector)
    if $prev_slide.length then $prev_slide else @get_slides().last()

  get_next_slide: ->
    $next_slide = @get_current_slide().next(@options.slide_selector)
    if $next_slide.length then $next_slide else @get_slides().first()

  # ---------------------------------------------------------------------

  init_slides: ->
    @set_current_slide(@get_initial_slide())

  on_key_press: (key) ->
    switch key
      when 37 then @go_to_prev_slide()
      when 39 then @go_to_next_slide()

  update_counter: ->
    current_slide_index = @get_slides().index(@get_current_slide())
    total_slides = @get_slides().length
    @get_counter().find(@options.counter_current_selector).html(current_slide_index+1)
    @get_counter().find(@options.counter_total_selector).html(total_slides)

  set_current_slide: ($slide) ->
    @get_slides().removeClass(@options.current_class_name).addClass(@options.inactive_class_name)
    @get_slides().removeAttr('aria-selected')
    $slide.removeClass(@options.inactive_class_name).addClass(@options.current_class_name)
    $slide.attr('aria-selected', true)
    @update_counter()
    @set_current_go_to_slide()
    @$element.trigger('slide_change', $slide.index()+1)

  set_current_go_to_slide: ->
    $current_slide = @get_current_slide()
    number = $current_slide.index()+1
    @get_go_to_slides().removeClass(@options.current_class_name)
    @get_go_to_slides().filter("[data-number='#{number}']").addClass(@options.current_class_name)

  go_to_slide: (number) ->
    $slide = @get_slides().eq(number-1)
    @set_current_slide($slide)

  go_to_prev_slide: -> @set_current_slide(@get_prev_slide())
  go_to_next_slide: -> @set_current_slide(@get_next_slide())

ImageGallery.init_plugin(ImageGallery, 'ImageGallery')
