import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'EventListModule__Content'

  @selector: ".event_list_module__content"

  on_init: ->
    @click_handler = (e) =>
      return unless @element
      return if $(e.target).closest('.event_list_module__button_to__tags').length
      @close_tags()
    $(document).on "click.#{@options.name}", @click_handler.bind(this)

    @$element.on "click.#{@options.name}", '.event_list_module__button_to__tags', (e) =>
      @toggle_tags()

  get_button_to_tags: -> @$element.find '.event_list_module__button_to__tags'
  get_tags: -> @$element.find '.event_list_module__tags'

  close_tags: ->
    @get_button_to_tags().removeClass 'event_list_module__button_to__is_on'
    @get_tags().removeClass 'event_list_module__tags__is_on'

  toggle_tags: ->
    @get_button_to_tags().toggleClass 'event_list_module__button_to__is_on'
    @get_tags().toggleClass 'event_list_module__tags__is_on'

Content.register()
