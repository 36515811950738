import MutationObserver from 'lib/modulor/mutation_observer'
import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'SimpleFormNestedFields__Wrapper'

  @selector: ".simple_form_nested_fields"

  on_init: ->
    { default: SimpleFormNestedFields } = await `import('./simple_form_nested_fields' /* webpackChunkName: "simple_form_nested_fields" */)`
    @plugin_instance = new SimpleFormNestedFields(@element)

  on_destroy: ->
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()

# make sure positions are updated everytime items are added or removed
MutationObserver.register(
  ".simple_form_nested_fields__item",
  ((el) => $(el).trigger('update_item_positions')), # on init
  ((el) => $(".simple_form_nested_fields--sortable").trigger('update_item_positions')) # on destroy
)
