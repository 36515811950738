import { default as WebModule__System } from 'models/modulor/web_module/system'

export class Content extends WebModule__System
  @defaults =
    debug: false
    name: 'FlashModule__Content'
    speed: 150
    dom_class:
      alert: "flash_module__flash,flash_module__flash__alert".split(',').map((i) -> "#{i}").join(' ')
      notice: "flash_module__flash,flash_module__flash__notice".split(',').map((i) -> "#{i}").join(' ')
      info: "flash_module__flash,flash_module__flash__info".split(',').map((i) -> "#{i}").join(' ')

  @selector: ".flash_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", ".flash_module__flash", (e) =>
      e.preventDefault()
      $flash = $(e.target)
      $flash.slideUp @options.speed, => $flash.remove()

    @$element.on "post.#{@options.name}", (e) =>
      @post_flash(e.detail.flash_type, e.detail.html)

  get_flash: -> @$element.find ".flash_module__flash"
  get_flash_dom_class: (type) -> @options.dom_class[type]

  build_flash: (type, content) ->
    dom_class = @get_flash_dom_class(type)
    $("<div>#{content}</div>").addClass(dom_class)

  post_flash: (type, content) -> @$element.append(@build_flash(type, content))

Content.register()
