import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'SimpleFormMarkdownEditor__Wrapper'

  @selector: ".simple_form_markdown_editor"

  on_init: ->
    { default: SimpleFormMarkdownEditor } = await `import('./simple_form_markdown_editor' /* webpackChunkName: "simple_form_markdown_editor" */)`
    @plugin_instance = new SimpleFormMarkdownEditor(@element)

  on_destroy: ->
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()
