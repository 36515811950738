import Plugin from 'lib/modulor/plugin'

export default class DatePicker__Input extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__DatePicker__Input'

  @selector: ".modulor_date_picker__input:not([type='hidden'])"

  on_init: ->
    return if @instance
    { default: @flatpickr } = await `import('../flatpickr' /* webpackChunkName: "flatpickr" */)`
    @instance = @flatpickr(@element, @get_options())

  on_destroy: ->
    if @instance
      @instance.destroy()
      @instance = undefined

  get_options: ->
    res = {}
    res['allowInput'] = true

    res['dateFormat'] = @get_format()

    res['maxDate'] = @get_max_date() if @get_max_date()
    res['minDate'] = @get_min_date() if @get_min_date()

    # res['static'] = true

    res['onOpen'] = (selectedDates, dateStr, instance) =>
      $(instance.calendarContainer)
        .addClass("modulor_date_picker__picker")
        .addClass(@get_date_picker_class())
    res['onClose'] = (selectedDates, dateStr, instance) =>
      $(instance.calendarContainer)
        .removeClass("modulor_date_picker__picker")
        .removeClass(@get_date_picker_class())

    res

  get_date_picker_class: -> @$element.data 'date-picker-class'
  get_format: -> @$element.data 'format'
  get_max_date: -> @$element.data 'max-date'
  get_min_date: -> @$element.data 'min-date'

DatePicker__Input.register()
