# Modulor.ReactComponent.register(Modulor.Grid)

import MutationObserver from 'lib/modulor/mutation_observer'

export default class ReactComponent
  instance = null

  @register: (react_class, react_class_name = react_class.name) ->
    instance ?= new PrivateClass()
    instance.register(react_class, react_class_name)
    instance.mountAll(react_class, react_class_name)

  @mount: (react_class, element) ->
    instance ?= new PrivateClass()
    instance.mount(react_class, element)

  @mountAll: (react_class) ->
    instance ?= new PrivateClass()
    instance.mountAll(react_class)

  @unmount: (react_class, element) ->
    instance ?= new PrivateClass()
    instance.unmount(react_class, element)

  @createElementFromName: (react_class_name, props) ->
    instance ?= new PrivateClass()
    instance.createElementFromName(react_class_name, props)

  class PrivateClass
    constructor: ->
      @observed_react_classes ?= {}

    register: (react_class, react_class_name) ->
      return if react_class_name of @observed_react_classes
      @observed_react_classes[react_class_name] = react_class

      MutationObserver.register(
        "[data-react-class='#{react_class_name}']",
        ((el) -> ReactComponent.mount(react_class, el)),
        ((el) -> ReactComponent.unmount(el))
      )

    mount: (react_class, element) ->
      unless !!global.React
        { default: React } = await `import('react' /* webpackChunkName: "react" */)`
        global.React = React

      unless !!global.ReactDOM
        { default: ReactDOM } = await `import('react-dom' /* webpackChunkName: "react-dom" */)`
        global.ReactDOM = ReactDOM

      props = JSON.parse(element.getAttribute('data-react-props'))
      react_element = global.React.createElement(react_class, props)
      global.ReactDOM.render(react_element, element)

    mountAll: (react_class, react_class_name) ->
      for element in document.querySelectorAll("[data-react-class='#{react_class_name}']")
        @mount(react_class, element)

    unmount: (react_class, element) ->
      unless !!global.React
        { default: React } = await `import('react' /* webpackChunkName: "react" */)`
        global.React = React

      unless !!global.ReactDOM
        { default: ReactDOM } = await `import('react-dom' /* webpackChunkName: "react-dom" */)`
        global.ReactDOM = ReactDOM

      global.ReactDOM.unmountComponentAtNode(element) if element

    createElementFromName: (react_class_name, props) ->
      return unless react_class = @observed_react_classes[react_class_name]

      unless !!global.React
        { default: React } = await `import('react' /* webpackChunkName: "react" */)`
        global.React = React

      global.React.createElement(react_class, props)
