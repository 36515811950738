import { default as WebModule__System } from 'models/modulor/web_module/system'

export class Content extends WebModule__System
  @defaults =
    debug: false
    name: 'AnalyticsModule__Content'

  @selector: ".analytics_module__content"

  on_init: ->
    @set_analytics_account()
    @add_analytics_script()
    @set_track_pageview()

  set_analytics_account: ->
    # Google Analytics depends on a global _gaq array. window is the global scope.
    if window
      window._gaq = []
      window._gaq.push ["_setAccount", @get_analytics_id()]

  set_track_pageview: (url) ->
    if window
      window._gaq.push ["_trackPageview"]
      window._gaq.push ["_trackPageLoadTime"]

  add_analytics_script: ->
    if document
      return if @get_analytics_script().length
      ga = document.createElement("script")
      ga.id = 'ga'
      ga.type = "text/javascript"
      ga.async = true
      ga.src = ((if "https:" is document.location.protocol then "https://ssl" else "http://www")) + ".google-analytics.com/ga.js"
      firstScript = document.getElementsByTagName("script")[0]
      firstScript.parentNode.insertBefore ga, firstScript

  get_analytics_id: -> @$element.data 'analytics-id'
  get_analytics_script: -> $('script#ga')

Content.register()
