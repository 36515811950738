import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'PageTreeViewModule__Loader'

  @selector: ".page_tree_view_module"

  on_init: ->
    `import('./page_tree_view_module' /* webpackChunkName: "page_tree_view_module" */)`

Loader.register()
