import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'NewPageModule__Loader'

  @selector: ".new_page_module"

  on_init: ->
    `import('./new_page_module' /* webpackChunkName: "new_page_module" */)`

Loader.register()
