import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'ResourceSelectInput__Loader'

  @selector: ".modulor_resource_select_input"

  on_init: ->
    `import('./resource_select_input_chunk' /* webpackChunkName: "modulor_resource_select_input" */)`

Loader.register()
