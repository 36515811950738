import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'PermissionListModule__Loader'

  @selector: ".permission_list_module"

  on_init: ->
    `import('./permission_list_module' /* webpackChunkName: "permission_list_module" */)`

Loader.register()
