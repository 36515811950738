export default class K
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()
    ctx.moveTo(
      Math.round(632 * scaleRatio + offsetX),
      Math.round(-10 * scaleRatio + offsetY + ctx.lineWidth / 2.0)
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX),
      Math.round(637 * scaleRatio + offsetY)
    )
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(
      Math.round(221 * scaleRatio + offsetX),
      Math.round(414 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(664 * scaleRatio + offsetX),
      Math.round(1010 * scaleRatio + offsetY)
    )
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(0 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY)
    )
    ctx.stroke()

  getWidth: (scaleRatio) ->
    664 * scaleRatio
