export default class U
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    p = new Path2D(
      "
      M  #{626 * scaleRatio + offsetX}, #{0 * scaleRatio + offsetY}
      l  #{0 * scaleRatio}, #{632 * scaleRatio}
      c  #{0 * scaleRatio}, #{85 * scaleRatio}  #{-6 * scaleRatio}, #{181 * scaleRatio}  #{-54 * scaleRatio}, #{253 * scaleRatio}
      c  #{-58 * scaleRatio}, #{87 * scaleRatio}  #{-155 * scaleRatio}, #{115 * scaleRatio}  #{-255 * scaleRatio}, #{115 * scaleRatio}
      C  #{194 * scaleRatio + offsetX}, #{1000 * scaleRatio + offsetY},  #{82 * scaleRatio + offsetX}, #{953 * scaleRatio + offsetY},  #{32 * scaleRatio + offsetX}, #{835 * scaleRatio + offsetY}
      C  #{2 * scaleRatio + offsetX}, #{758 * scaleRatio + offsetY},  #{1 * scaleRatio + offsetX}, #{674 * scaleRatio + offsetY},  #{1 * scaleRatio + offsetX}, #{594 * scaleRatio + offsetY}
      L  #{1 * scaleRatio + offsetX}, #{0 * scaleRatio}
      "
    )

    # M (x,y)
    # m (dx,dy)
    # L (x, y)
    # l (dx, dy)
    # c (dx1,dy1, dx2,dy2, dx,dy)
    # C (x1,y1, x2,y2, x,y)

    ctx.stroke(p)

  getWidth: (scaleRatio) ->
    626 * scaleRatio
