export default class W
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()
    ctx.moveTo(Math.round(0 * scaleRatio + offsetX), Math.round(-10 * scaleRatio + offsetY))
    ctx.lineTo(Math.round(300 * scaleRatio + offsetX), Math.round(1010 * scaleRatio + offsetY))
    ctx.lineTo(Math.round(589 * scaleRatio + offsetX), Math.round(-10 * scaleRatio + offsetY))
    ctx.lineTo(Math.round(874 * scaleRatio + offsetX), Math.round(1010 * scaleRatio + offsetY))
    ctx.lineTo(Math.round(1175 * scaleRatio + offsetX), Math.round(-10 * scaleRatio + offsetY))
    ctx.stroke()

  getWidth: (scaleRatio) ->
    1175 * scaleRatio
