import Plugin from 'lib/modulor/plugin'

# TODO: replace moment with date-fns

export default class Timeago extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Timeago'
    interval: 1000*45

  @selector: '.timeago[datetime]'

  on_init: ->
    return if @intervalId
    { default: @moment } = await `import('moment' /* webpackChunkName: "moment" */)`
    return unless @element
    return unless @should_convert()

    @intervalId = null

    @original_html = @$element.html()
    @original_text = @$element.text()

    @update_value()
    @startUpdating()

  on_destroy: ->
    @stopUpdating()

  should_convert: -> @moment().diff(@get_datetime())

  get_datetime: -> @$element.attr('datetime')
  get_converted_value: -> @moment(@get_datetime()).fromNow()

  update_value: ->
    if @should_convert()
      @$element.html(@get_converted_value())
    else
      @$element.html(@original_html)
      @stopUpdating()

  startUpdating: ->
    @intervalId = setInterval ( => @update_value() ), @options.interval

  stopUpdating: ->
    clearInterval(@intervalId) if @intervalId
    @intervalId = undefined

Timeago.register()
