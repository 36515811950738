# export class PressList extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'PressListModule'
#
#   @selector: ".press_list_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# PressList.register()
