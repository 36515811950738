import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./baseline' /* webpackChunkName: "baseline" */)`,
  loading: -> <Loading />

export default class LoadableBaseline extends Component
  render: -> <LoadableComponent {...this.props} />
