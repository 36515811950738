import Page from 'models/modulor/page/page'
import ScrollIntoView from 'lib/modulor/scroll_into_view'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Article extends Page
  @defaults =
    debug: false
    name: 'ArticlePage'

  @selector: ".article_page__is_modal"

  @property "$web_modules",
    get: -> @$element.children(".modulor_web_modules")

  @property "$image_module",
    get: -> @$element.find(".image_module__content")

  on_init: ->
    # disableBodyScroll(@element) if @element

    @$image_module.css("cursor", "pointer")

    @$element.on "click.#{@options.name}", ".image_module__content img", (e) =>
      @scroll_to_article_header()

  on_destroy: ->
    # enableBodyScroll(@element) if @element
    @$image_module.css("cursor", "initial")

  scroll_to_article_header: ->
    @$web_modules.animate({ scrollTop: @$image_module.outerHeight() }, 500)

Article.register()
