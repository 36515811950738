import Plugin from 'lib/modulor/plugin'

export default class ExpandableText extends Plugin
  @defaults =
    debug: false
    name: "ExpandableText"

  @selector: '.expandable_text_module__expandable_text'

  on_init: ->
    @$element.on "click.#{@options.name}", '.expandable_text_module__expandable_text__tab', (e) =>
      @toggle_expanded()

  get_body: -> @$element.find('.expandable_text_module__expandable_text__body')
  get_button: -> @get_tab().find 'button'
  get_tab: -> @$element.find '.expandable_text_module__expandable_text__tab'

  toggle_expanded: ->
    $button = @get_button()
    $body = @get_body()

    if $button.attr('aria-expanded') == 'true'
      $button.attr('aria-expanded', 'false')
      $body.attr('aria-hidden', 'true')
    else
      $button.attr('aria-expanded', 'true')
      $body.attr('aria-hidden', 'false')

ExpandableText.register()
