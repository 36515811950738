# this is to bind the edit mode functionality fo regular modules
# to the dummy module as well

import WebModule__IsEditMode from '../web_module/__is_edit_mode'

export default class DummyModule__IsEditMode extends WebModule__IsEditMode
  @defaults =
    debug: false
    name: 'Modulor__DummyModule__IsEditMode'

  @selector: ".modulor_dummy_module"

DummyModule__IsEditMode.register()
