import ToDialog from 'lib/modulor/to_dialog/to_dialog'

export default class Link__ToDialog extends ToDialog
  @defaults =
    debug: false
    delay: 10
    name: 'Modulor__Link__ToDialog'

  @selector: ".modulor_link__to_dialog"

  on_init: ->
    super()

    @$element.on "click.#{@options.name}", (e) =>
      e.preventDefault()
      @show_dialog()
      setTimeout (=> @load_dialog_content()), @options.delay # wait for the dialog to init

Link__ToDialog.register()
