import Plugin from 'lib/modulor/plugin'

export class Gdpr extends Plugin
  @defaults =
    debug: false

  @selector: '.gdpr__container'

  on_init: ->
    if @get_local_storage_consent() == "agree"
      @agree()
    else if @get_local_storage_consent() == "disagree"
      @disagree()
    else
      @show_consent_banner()

    @$element.on 'click.Gdpr', ".gdpr__button[data-value='agree']", (e) =>
      @agree()

    @$element.on 'click.Gdpr', ".gdpr__button[data-value='disagree']", (e) =>
      @disagree()

  get_local_storage_consent: ->
    localStorage.getItem("KMKrefeld--GDPRconsent")

  set_local_storage_consent: (val) ->
    localStorage.setItem("KMKrefeld--GDPRconsent", val)

  get_template_target: ->
    @element.querySelector(".gdpr__template")

  show_consent_banner: ->
    @element.removeAttribute("hidden")

  hide_consent_banner: ->
    @element.setAttribute("hidden", "")

  inject_google_analytics: ->
    analytics = @get_template_target().content.cloneNode(true)
    document.head.appendChild(analytics)

  agree: ->
    @set_local_storage_consent("agree")
    @hide_consent_banner()
    @inject_google_analytics()

  disagree: ->
    @set_local_storage_consent("disagree")
    @hide_consent_banner()

Gdpr.register()
