import WebModule from './web_module'
import ScrollIntoView from 'lib/modulor/scroll_into_view'

export default class WebModule__IsEditMode extends WebModule
  @defaults =
    debug: false
    name: 'Modulor__WebModule__IsEditMode'
    scroll_into_view_speed: 100

  @selector: ".modulor_web_module__is_edit_mode"

  on_init: ->
    @$element.on "add_new.#{@options.name}", (e) =>
      # TODO: handle errors here
      e.stopPropagation()
      return unless e.detail && e.detail.content
      @add_new e.detail.content

    @$element.on "show.#{@options.name} edit.#{@options.name} update.#{@options.name}", (e) =>
      # TODO: handle errors here
      e.stopPropagation()
      return unless e.detail && e.detail.content
      @replace e.detail.content

    @$element.on "create.#{@options.name}", (e) =>
      # TODO: handle errors here
      e.stopPropagation()
      return unless e.detail && e.detail.content
      @replace e.detail.content, ($el) =>
        $el.trigger('update_web_module_positions') if (e.type is 'create')

    @$element.on "destroy.#{@options.name}", (e) =>
      # TODO: handle errors here
      e.stopPropagation()
      $parent = @$element.parent()
      @remove_and_destroy =>
        $parent.trigger('update_web_module_positions')

    @$element.on "insert.#{@options.name}", (e) =>
      e.stopPropagation()
      @scroll_into_view(@$element)

    @$element.on "change.#{@options.name}", ".modulor_web_module__add select#modulor_web_module__type", (e) =>
      e.stopPropagation()
      $select = $(e.target)
      if $select.val().length > 0
        $form = $select.closest('form')
        $button = $form.find('input[type="submit"]')
        $button.click()
        @reset_web_module_selector()

    @$element.on "click.#{@options.name}", ".modulor_link__action_cancel__is_new_record, .modulor_button__action_destroy__is_new_record", (e) =>
      e.stopPropagation()
      e.preventDefault()
      @$element.trigger("destroy.#{@options.name}")

    @$element.on "click.#{@options.name}", ".modulor_button__direction", (e) =>
      e.preventDefault()
      e.stopPropagation()
      $button = $(e.target)
      direction = $button.data('direction')
      $other_el = if direction == 'down' then @$element.next() else @$element.prev()
      return unless $other_el.length
      return if $other_el.is(".modulor_dummy_module")
      @$element.swap $other_el, callback: =>
        @$element.trigger('update_web_module_positions')

    # @$element.on "show.#{@options.name} edit.#{@options.name} create.#{@options.name} update.#{@options.name}", (e) =>
    #   e.stopPropagation()
    #   @scroll_into_view()

  # ---------------------------------------------------------------------

  get_add_select: -> @$element.find ".modulor_web_module__add select#modulor_web_module__type"
  get_direction_button: -> @$element.find(".modulor_button__direction")
  get_id: -> @$element.data('id')

  # ---------------------------------------------------------------------

  scroll_into_view: ($el) ->
    ScrollIntoView.call($el.find('.modulor_web_module__buttons'))

  add_new: (content, callback) ->
    $content = $(content)
    $content.hide()
    @$element.before($content)
    $content.animate { height: 'show' }, @options.new_speed, () =>
      $content.trigger("insert.#{@options.name}")
      callback.call this, $content if callback

  remove_and_destroy: (callback) ->
    @$element.css 'overflow', 'hidden'
    @$element.animate { height: 0 }, @options.destroy_speed, () =>
      # @on_destroy()
      @$element.remove()
      callback.call this if callback

  reset_web_module_selector: -> @get_add_select().val('')

  replace: (content, callback) ->
    $content = $(content)
    @$element.replaceWith($content)
    callback.call this, $content if callback
    @scroll_into_view($content)

# =====================================================================

WebModule__IsEditMode.register()
