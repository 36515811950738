# export class PublicationList extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'PublicationListModule'
#
#   @selector: ".publication_list_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# PublicationList.register()
