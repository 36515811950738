import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'TwitterModule__Loader'

  @selector: ".twitter_module"

  on_init: ->
    `import('./twitter_module' /* webpackChunkName: "twitter_module" */)`

Loader.register()
