import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'AudioModule__Loader'

  @selector: ".audio_module"

  on_init: ->
    `import('./audio_module' /* webpackChunkName: "audio_module" */)`

Loader.register()
