import 'custom-event-polyfill'

import './autosize'
import './date_picker'
import './date_range_pickers'
import './date_time_picker'
import './has_modulor_tip'
import './hashtags'
import './lazysizes'
import './picturefill'
import './recurring_select'
import './simple_form_attachments'
import './simple_form_dependent_fields'
import './simple_form_nested_fields'
import './simple_form_markdown_editor'
import './swap'
import './time_picker'
import './tip'
