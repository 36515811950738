import generate_uuid from 'lib/modulor/generate_uuid'
import Plugin from 'lib/modulor/plugin'

export class Background extends Plugin
  @defaults =
    debug: false
    name: 'ModulorLink__ActionDownload__Background'

  @selector: ".modulor_link__action_download__background"

  on_init: ->
    @uuid = generate_uuid()
    @ActionCable = await `import('actioncable' /* webpackChunkName: "actioncable" */)`
    @FileSaver = await `import('file-saver' /* webpackChunkName: "file-saver" */)`
    @$element.on "click.#{@options.name}", (e) =>
      e.preventDefault()
      @$element.attr('disabled', 'disabled')
      @create_subscription()

  on_destroy: ->
    @subscription?.unsubscribe()
    @cable?.disconnect()

  get_url: -> @$element.attr('href')
  get_url_with_uuid: ->
    url = @get_url()
    query_join = if ("?" in url) then "&" else "?"
    url = [url, "uuid=#{@uuid}"].join(query_join)

  create_subscription: ->
    @cable = @ActionCable.createConsumer()
    @subscription = @cable.subscriptions.create { channel: "Modulor::DownloadChannel", uuid: @uuid },
      connected: @on_connect.bind(@)
      disconnected: @on_disconnect.bind(@)
      received: (data) =>
        @save_file(data)
        $.rails.enableElement(@$element)
        @subscription?.unsubscribe()
        @cable?.disconnect()

  on_connect: -> $.get(@get_url_with_uuid())
  on_disconnect: ->

  save_file: (data) ->
    b64_data = data.b64_data.replace(/^data:\w+\/\w+;base64,/, "")
    buf = new Buffer(b64_data, 'base64')
    blob = new Blob([buf], { type: data.mime_type })
    @FileSaver.saveAs(blob, data.file_name)

Background.register()
