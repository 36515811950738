import Plugin from 'lib/modulor/plugin'

import { isFuture, isPast, parse } from 'date-fns'

export class Thumbnail extends Plugin
  @defaults =
    debug: false
    name: 'ExhibitionPage__Thumbnail'

  @selector: ".exhibition_page__thumbnail"

  on_init: ->
    if isFuture(@get_from())
      @get_date_range().addClass('exhibition_page__thumbnail__date_range__from')

    if isPast(@get_from()) && isFuture(@get_until())
      @get_date_range().addClass('exhibition_page__thumbnail__date_range__until')

  on_destroy: ->
    @get_date_range().removeClass('exhibition_page__thumbnail__date_range__from')
    @get_date_range().removeClass('exhibition_page__thumbnail__date_range__until')

  get_from: -> parse @get_date_range().find('.semantic.from').attr('datetime')
  get_until: -> parse @get_date_range().find('.semantic.to').attr('datetime')

  get_date_range: -> @$element.find('.exhibition_page__thumbnail__date_range')
  get_from_string: -> @get_date_range().data('from')
  get_until_string: -> @get_date_range().data('until')

  is_from: -> dateQueries(dtstart, dtend, [query])
  is_until: -> dateQueries(dtstart, dtend, [query])

Thumbnail.register()
