import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class RelatedPast extends WebModule__Content
  @defaults =
    debug: false
    debounce: 100
    name: 'EventListModule__RelatedPast'

  @selector: ".event_list_module__content[data-view-option='related_past']"

  on_init: ->
    @get_footer_module().attr('data-past', 'true')

  on_destroy: ->

  get_footer_module: -> $('.footer_module__content')

RelatedPast.register()
