import Plugin from 'lib/modulor/plugin'

export class LinkToIndex extends Plugin
  @defaults =
    debug: false
    name: 'SiteMenuModule__LinkToIndex'

  @selector: ".site_menu_module__link_to_index"

  on_init: -> @distribute()
  on_resize: -> @distribute()
  on_load: -> @distribute()
  on_ready: -> @distribute()
  on_turbolinks_render: -> @distribute()

  get_available_width: -> @get_container_width() - @get_first_letter_width()/2 - @get_last_letter_width()/2
  get_container_width: -> @$element.width()
  get_first_letter_width: -> @get_letters().filter(':first-child').width()
  get_last_letter_width: -> @get_letters().filter(':last-child').width()
  get_letters: -> @$element.find(".site_menu_module__link_to_index__letter")
  get_letters_to_distribute: -> @get_letters().filter(':not(:first-child):not(:last-child)')

  distribute: ->
    @get_letters_to_distribute().each (i, el) =>
      ratio = @get_available_width() / (@get_letters_to_distribute().length + 1)
      left = (ratio * (i+1)) + @get_first_letter_width()/2
      $(el).css 'left', "#{left}px"

LinkToIndex.register()
