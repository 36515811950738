import { default as Page__System } from 'models/modulor/page/system'

export default class WebModulePreviewPage extends Page__System
  @defaults =
    debug: false
    name: 'WebModulePreviewPage'

  @selector: ".web_module_preview_page"

  on_init: ->
    @$element.on "click.#{@options.name}", 'a', (e) =>
      e.stopImmediatePropagation()
      e.preventDefault()
      alert(@get_block_message())

  get_block_message: -> @$element.data('block-message')

WebModulePreviewPage.register()
