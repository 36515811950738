import _debounce from 'lodash/debounce'
import Plugin from 'lib/modulor/plugin'

export default class Lightbox extends Plugin
  @defaults =
    debug: false
    debounce: 100
    name: 'ImagesModule__Attachments__Lightbox'

  @selector: ".images_module__attachments__lightbox"

  on_init: ->
    { default: @Flickity } = await `import('flickity' /* webpackChunkName: "flickity" */)`

    @flickity = new @Flickity(@element,
      adaptiveHeight: true
      cellAlign: 'left'
      cellSelector: ".images_module__attachment"
      initialIndex: @$element.data('initial-index')
      pageDots: false
      prevNextButtons: false
    )

    @$element.on "click.#{@options.name}", ".images_module__buttons__button__prev", (e) =>
      e.stopPropagation()
      @flickity.previous()

    @$element.on "click.#{@options.name}", ".images_module__buttons__button__next", (e) =>
      e.stopPropagation()
      @flickity.next()

    @$element.on "select.flickity", (e, index) =>
      e.stopImmediatePropagation()
      @set_nav_index(index)

    @font_load_handler = _debounce(@resize_flickity.bind(this), @options.debounce)
    $('html').on "font-load.#{@options.name}", @font_load_handler

    lazySizes.autoSizer.checkElems()

    @$element.prepend @get_nav_buttons_markup()

    @set_nav_index(@flickity.selectedIndex)

  on_destroy: ->
    @flickity.destroy() if @flickity
    @$element.off '.flickity'
    $('html').off('font-load', @font_load_handler) if @font_load_handler

  get_attachments: -> @$element.find('.images_module__attachment')

  get_prev_button_markup: -> $("
    <button class='images_module__buttons__button images_module__buttons__button__prev'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 0,50 50,100' />
      </svg>
    </button>
  ")

  get_next_button_markup: -> $("
    <button class='images_module__buttons__button images_module__buttons__button__next'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='50' x2='100' y2='50' />
        <polyline points='50,0 100,50 50,100' />
      </svg>
    </button>
  ")

  get_nav_buttons: -> @$element.find('.images_module__buttons__nav')
  get_prev_button: -> @get_nav_buttons().find('.images_module__buttons__button__prev')
  get_next_button: -> @get_nav_buttons().find('.images_module__buttons__button__next')

  get_nav_buttons_markup: ->
    res = $("<div class='images_module__buttons__nav' />")
    if @get_attachments().length > 1
      res.append(@get_prev_button_markup())
      res.append(@get_next_button_markup())
    res.append(@get_close_button_markup())
    res

  get_close_button_markup: -> $("
    <button class='images_module__buttons__button images_module__buttons__button__close'>
      <svg viewBox='0 0 100 100'>
        <line x1='0' y1='0' x2='100' y2='100' />
        <line x1='100' y1='0' x2='0' y2='100' />
      </svg>
    </button>
  ")

  resize_flickity: -> @flickity.resize() if @flickity

  select: (index) -> @flickity.select(index) if @flickity

  set_nav_index: (index) ->
    if @flickity.slides.length <= 1
      @get_prev_button().addClass('images_module__buttons__button__inactive')
      @get_next_button().addClass('images_module__buttons__button__inactive')
    else
      @get_prev_button().toggleClass('images_module__buttons__button__inactive', (index == 0))
      @get_next_button().toggleClass('images_module__buttons__button__inactive', (index >= @flickity.slides.length - 1))

Lightbox.register()
