import MutationObserver from 'lib/modulor/mutation_observer'

tippyOptions = {
  arrow: true,
  duration: 100,
  onShow: -> $(this).addClass("modulor_tip")
}

MutationObserver.register(
  ".has_modulor_tip",
  ((el) ->
    { default: tippy } = await `import('../tippy' /* webpackChunkName: "tippy" */)`
    tippy(el, tippyOptions)
  ),
  # ((el) ->  ) # TODO: find a way to destroy
)

init_tippy = ->
  { default: tippy } = await `import('../tippy' /* webpackChunkName: "tippy" */)`
  tippy(".has_modulor_tip", tippyOptions)

init_tippy() if $(".has_modulor_tip").length > 0
