import Plugin from 'lib/modulor/plugin'

export default class Version extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Page__Version'

  @selector: ".modulor_page_version"

Version.register()
