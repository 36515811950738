import Plugin from 'lib/modulor/plugin'

export default class Dialog extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Dialog'

  @selector: ".modulor_dialog"

  @build: (options) ->
    attr = options.attr || {}
    dom_class = options.class
    loading_string = options.loading_string
    title = options.title
    $dialog = $('<div />')
      .appendTo('body')
      .addClass("modulor_dialog")
      .addClass(dom_class)
      .prop('title', title)
      .attr('open', 'open')
      .append(@build_container(title, loading_string))

    $dialog.attr(key, value) for key, value of attr
    $dialog

  @build_container: (title, loading_string) ->
    $('<div />')
      .addClass("modulor_dialog__container")
      .append(@build_header(title))
      .append(@build_loading(loading_string))
      .append(@build_content())

  @build_content: ->
    $('<div />')
      .addClass("modulor_dialog__content")

  @build_header: (title) ->
    $('<div />')
      .html(title)
      .addClass("modulor_dialog__header")
      .append(@build_header_close())

  @build_header_close: ->
    $('<div />')
      .addClass("modulor_dialog__header__close")

  @build_loading: (loading_string) ->
    $('<div />')
      .html(loading_string ? 'Loading …')
      .addClass("modulor_dialog__loading")

  on_init: ->
    @$element.on "cancel.#{@options.name}", (e) => # replace the default 'cancel' handler
      e.preventDefault()
      @close()

    @$element.on "click.#{@options.name}", ".modulor_link__action_cancel, .modulor_dialog__header__close", (e) =>
      e.preventDefault()
      e.stopImmediatePropagation()
      @close()

    @$element.on "close_dialog.#{@options.name}", (e) =>
      e.preventDefault()
      @close()

    @$element.on "show_loading.#{@options.name}", (e) => @show_loading()
    @$element.on "hide_loading.#{@options.name}", (e) => @hide_loading()

    @$element.on "replace_content.#{@options.name}", (e) =>
      @hide_loading()
      @get_content().html(e.detail.content)
      @focus_first_input()

    @$element.on "click.#{@options.name}", ".modulor_link__keep_in_dialog", (e) =>
      e.preventDefault()
      return unless url = $(e.target).prop('href')
      @show_loading()
      $.get url, (response) =>
        @hide_loading()
        @get_content().html(response)
        @focus_first_input()

  on_keyup: (e) ->
    return if @is_form()

    switch e.keyCode
      when 27
        @close()

  close: ->
    @$element.removeAttr('open')
    @$element.remove()

  focus_first_input: -> @$element.find('form').find('input, textarea, select').not('[type=hidden]').first().focus()
  hide_loading: -> @$element.removeClass("modulor_dialog__is_loading")
  show_loading: -> @$element.addClass("modulor_dialog__is_loading")

  get_loading: -> @$element.find ".modulor_dialog__loading"
  get_content: -> @$element.find ".modulor_dialog__content"

  is_form: -> @get_content().find('form').length

Dialog.register()
