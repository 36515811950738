import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'AttachmentPageSelector__Loader'

  @selector: ".attachment_page_selector__container"

  on_init: ->
    `import('./attachment_page_selector' /* webpackChunkName: "attachment_page_selector" */)`

Loader.register()
