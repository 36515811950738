import 'lib/modulor/image_gallery'

import { default as WebModule__Content } from 'models/modulor/web_module/content'

export default class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ImagesModule__Content'

  @selector: ".images_module__content"

  on_init: ->
    @get_images_container().ImageGallery()

  on_destroy: ->
    @get_images_container().ImageGallery('destroy')

  get_images_container: -> @$element.find(".images_module__attachment_images")

Content.register()
