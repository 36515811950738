import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'WebModulePreviewModule__Loader'

  @selector: ".web_module_preview_module"

  on_init: ->
    `import('./web_module_preview_module' /* webpackChunkName: "web_module_preview_module" */)`

Loader.register()
