# export class Content extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'PublicationHeaderModule__Content'
#
#   @selector: ".publication_header_module__content"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Content.register()
