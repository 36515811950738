# export class Content extends Modulor.WebModule
#   @defaults =
#     debug: false
#     name: 'PressHeaderModule__Form'
#
#   @selector: ".press_header_module__form"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# Form.register()
