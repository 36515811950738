import Plugin from 'lib/modulor/plugin'

export default class Page extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Page'

  @selector: ".modulor_page"

  # get_id: -> @$element.data 'id'
  # get_path: -> @$element.data 'path'
  # get_depth: -> @$element.data 'depth'

  get_web_modules_container: -> @$element.find(".modulor_web_modules").first()
  get_web_modules: -> @get_web_modules_container().children(".modulor_web_module")
