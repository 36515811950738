import Plugin from 'lib/modulor/plugin'

export class MobileMenu extends Plugin
  @defaults =
    debug: false
    name: 'SiteMenuModule__MobileMenu'

  @selector: ".site_menu_module__mobile_menu"

  on_init: ->
    @$element.on "click.#{@options.name}", ".site_menu_module__mobile_menu__label", (e) =>
      @$element.toggleClass("site_menu_module__mobile_menu__is_open")

MobileMenu.register()
