import Plugin from 'lib/modulor/plugin'

export default class SubmitForReviewToggle extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__Draft__SubmitForReviewToggle'

  @selector: ".modulor_draft_submit_for_review_toggle"

  on_init: ->
    @$element.on "click.#{@options.name}", 'input[name*="[for_review]"]', (e) =>
      @get_form().trigger('submit.rails')

    @$element.on "update.#{@options.name}", (e) =>
      @$element.replaceWith(e.html)

  get_form: -> @$element.find(".modulor_form")

SubmitForReviewToggle.register()
