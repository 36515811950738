export default class H
  render: (ctx, scaleRatio, offsetX, offsetY) ->
    ctx.beginPath()

    ctx.moveTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(0 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY)
    )

    ctx.stroke()

    ctx.beginPath()

    ctx.moveTo(
      Math.round(642 * scaleRatio + offsetX - ctx.lineWidth / 2.0),
      Math.round(0 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(642 * scaleRatio + offsetX - ctx.lineWidth / 2.0),
      Math.round(1000 * scaleRatio + offsetY)
    )

    ctx.stroke()

    ctx.beginPath()

    ctx.moveTo(
      Math.round(0 * scaleRatio + offsetX + ctx.lineWidth / 2.0),
      Math.round(493 * scaleRatio + offsetY)
    )
    ctx.lineTo(
      Math.round(642 * scaleRatio + offsetX - ctx.lineWidth / 2.0),
      Math.round(493 * scaleRatio + offsetY)
    )

    ctx.stroke()

  getWidth: (scaleRatio) ->
    641.9 * scaleRatio
