import { Component } from 'react'
import Loadable from 'react-loadable'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./activity_list' /* webpackChunkName: "activity_list" */)`,
  loading: -> <Loading />

export default class LoadableActivityList extends Component
  render: -> <LoadableComponent {...this.props} />
