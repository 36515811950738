import { default as WebModule__Content } from 'models/modulor/web_module/content'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'ArticleHeaderModule__Content'

  @selector: ".article_header_module__content"

  @property "$heading",
    get: -> @$_heading ||= @$element.find('.article_header_module__heading:not(.clone)')

  @property "$heading_clone",
    get: -> @$_heading_clone ||= @$heading.clone().addClass('clone')

  @property "user_signed_in",
    get: -> $("body").hasClass("user_signed_in")

  on_init: ->
    if @$element.closest(".modulor_web_modules").position().left == 0
      @$element.prepend(@$heading_clone)
      @$heading.css("visibility", "hidden")
    else
      $("body").one "transitionend.#{@options.name}", ".article_page__is_modal", (e) =>
        @$element.prepend(@$heading_clone)
        @$heading.css("visibility", "hidden")

  on_destroy: ->
    @$heading.css("visibility", null)
    @$heading_clone.remove()

Content.register()
