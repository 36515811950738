import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Clone extends Plugin
  @defaults =
    debug: false
    name: 'ArticleHeaderModule__Heading__Clone'

  @selector: ".article_header_module__heading.clone"

  @property "image_module",
    get: -> @modal_scroll_area.querySelector(".image_module__content")

  @property "original_heading",
    get: -> document.querySelector(".article_header_module__heading:not(.clone)")

  @property "modal_scroll_area",
    get: -> document.querySelector(".article_page__is_modal").querySelector(".modulor_web_modules")

  @property "observer",
    get: ->
      options = {
        root: @modal_scroll_area,
        threshold: 0
      }
      @_observer ||= new IntersectionObserver(@handleObserver.bind(@), options)

  @property "state",
    get: -> @$element.attr("data-state")
    set: (val) -> @$element.attr("data-state", val)

  on_init: ->
    if @image_module
      @observer.observe(@image_module)
    else
      @state = "bg"

  on_destroy: ->
    if @image_module
      @observer.disconnect()
    @state = null

  handleObserver: (entries, observer) ->
    for entry in entries
      if entry.boundingClientRect.bottom > entry.rootBounds.top
        @state = null
      else
        @state = "bg"

Clone.register()
