import Plugin from 'lib/modulor/plugin'

export default class Semantic extends Plugin
  @defaults =
    debug: false
    name: "Semantic__Time"

  @selector: "time.semantic[datetime]"

  on_init: ->
    { default: @moment } = await `import('moment' /* webpackChunkName: "moment" */)`
    return unless @element
    return unless @get_converted_value()
    @set_current_year_class()
    @set_past_class()

  get_datetime: -> @$element.attr('datetime')
  get_converted_value: -> @moment(@get_datetime())

  is_current_year: -> @get_converted_value().year() == @moment().year()
  is_past: -> @get_converted_value() < @moment()

  set_current_year_class: -> @$element.toggleClass('current_year', @is_current_year())
  set_past_class: -> @$element.toggleClass('past', @is_past())

Semantic.register()
