export default class MutationObserver
  instance = null

  @register: (selector, callback_add, callback_remove) ->
    instance ?= new PrivateClass()
    instance.register(selector, callback_add, callback_remove)

  @start: ->
    instance ?= new PrivateClass()
    instance.start()

  @stop: ->
    instance ?= new PrivateClass()
    instance.stop()

  @get_observed_selectors: ->
    instance ?= new PrivateClass()
    instance.get_observed_selectors()

  class PrivateClass
    constructor: ->
      @observed_selectors ?= []

    register: (selector, callback_add, callback_remove) ->
      unless @observed_selectors.find((i) -> (i.selector == selector && i.callback_add == callback_add && i.callback_remove == callback_remove))
        @observed_selectors.push({ selector: selector, callback_add: callback_add, callback_remove: callback_remove })

    start: ->
      return if @observer

      @observer = new window.MutationObserver (mutations) ->
        for mutation in mutations
          if mutation.addedNodes.length > 0
            for { selector, callback_add, callback_remove } in MutationObserver.get_observed_selectors()
              if callback_add
                $(mutation.addedNodes).find(selector).addBack().filter(selector).each (i, el) =>
                  # console.log "ADD", selector, el
                  callback_add.call(@, el)

          if mutation.removedNodes.length > 0
            for { selector, callback_add, callback_remove } in MutationObserver.get_observed_selectors()
              if callback_remove
                $(mutation.removedNodes).find(selector).addBack().filter(selector).each (i, el) =>
                  # console.log "REMOVE", selector, el
                  callback_remove.call(@, el)

      @observer.observe(document.documentElement, childList: true, subtree: true)

    stop: ->
      return unless @observer
      @observer.disconnect()

    get_observed_selectors: -> @observed_selectors
