import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'VideoModule__Loader'

  @selector: ".video_module"

  on_init: ->
    `import('./video_module' /* webpackChunkName: "video_module" */)`

Loader.register()
