import './styles'

import Loadable from 'react-loadable'
import { Component } from 'react'

import Loading from 'components/modulor/loading'

LoadableComponent = Loadable
  loader: -> `import('./preview' /* webpackChunkName: "preview" */)`,
  loading: -> <Loading />

export default class LoadablePreview extends Component
  render: -> <LoadableComponent {...this.props} />
