import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'PdfViewerModule__Loader'

  @selector: ".pdf_viewer_module"

  on_init: ->
    `import('./pdf_viewer_module' /* webpackChunkName: "pdf_viewer_module" */)`

Loader.register()
