import Plugin from 'lib/modulor/plugin'

export default class Wrapper extends Plugin
  @defaults =
    debug: false
    name: 'Hashtags__Wrapper'

  @selector: "textarea[data-hashtags]"

  on_init: ->
    { default: Hashtags } = await `import('./hashtags' /* webpackChunkName: "hashtags" */)`
    @plugin_instance = new Hashtags(@element)

  on_destroy: ->
    @plugin_instance.destroy() if @plugin_instance
    @plugin_instance = undefined

Wrapper.register()
