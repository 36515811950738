# export class PublicationHeader extends Modulor.WebModule__Content
#   @defaults =
#     debug: false
#     name: 'PublicationHeaderModule'
#
#   @selector: ".publication_header_module"
#
#   on_init: ->
#     console.log 'init' if @options.debug
#
# PublicationHeader.register()
