import Plugin from 'lib/modulor/plugin'

export default class TimePicker__Input extends Plugin
  @defaults =
    debug: false
    name: 'Modulor__TimePicker__Input'

  @selector: ".modulor_time_picker__input" + ":not([type='hidden'])"

  on_init: ->
    return if @instance
    { default: flatpickr } = await `import('../flatpickr' /* webpackChunkName: "flatpickr" */)`
    @instance = flatpickr(@element, @get_options())

  on_destroy: ->
    if @instance
      @instance.destroy()
      @instance = undefined

  get_options: ->
    res = {}
    res['allowInput'] = true
    res['enableTime'] = true
    res['noCalendar'] = true
    res['time_24hr'] = true

    res['dateFormat'] = @get_format()

    res['onOpen'] = (selectedDates, dateStr, instance) =>
      $(instance.calendarContainer)
        .addClass("modulor_time_picker__picker")
        .addClass(@get_time_picker_class())
    res['onClose'] = (selectedDates, dateStr, instance) =>
      $(instance.calendarContainer)
        .removeClass("modulor_time_picker__picker")
        .removeClass(@get_time_picker_class())
    res

  get_time_picker_class: -> @$element.data 'time-picker-class'
  get_format: -> @$element.data 'format'

TimePicker__Input.register()
