import Plugin from 'lib/modulor/plugin'

export default class Loader extends Plugin
  @defaults =
    debug: false
    name: 'RegistrationModule__Loader'

  @selector: ".registration_module"

  on_init: ->
    `import('./registration_module' /* webpackChunkName: "registration_module" */)`

Loader.register()
