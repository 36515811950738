import Plugin from 'lib/modulor/plugin'

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class Section extends Plugin
  @selector: ".anders_wohnen_header_module__section"

  on_init: ->
    @$element.css("cursor", "pointer")

    @$element.on "click.#{@options.name}", (e) =>
      @scroll_to_next()

  on_destroy: ->
    @$element.css("cursor", "initial")

  scroll_to_next: ->
    $("html, body").animate({ scrollTop: (@$element.offset().top + @$element.outerHeight()) }, 500)

Section.register()
